<template>
  <div>
    <div class="d-flex justify-center py-2">
      <EmTerciaryButton @click="show = !show">
        {{ show ? 'Hide' : 'Show' }} details
      </EmTerciaryButton>
    </div>
    <v-expand-transition>
      <div v-show="show" class="code">
        <div v-for="line in stack" :key="line" class="pb-05">
          <div class="bg-bggrey px-2 py-025 break-all">
            {{ line }}
          </div>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script setup>
const props = defineProps({
  error: { type: Object, required: true }
})
const show = ref(false)
const stack = computed(() => props.error.stack.split('\n'))
</script>

<style scoped>
.code {
  font-size: 12px;
  font-family: monospace;
}
</style>
