import { RouteRecordName } from 'vue-router'
import { Service } from '@/services/helpers/ServiceHelper'
import { Translation } from '@/services/injectables/api/IdTranslatorService'

const isNumber = (value: any) => value !== null && !isNaN(value)
export default defineNuxtRouteMiddleware(async (to) => {
  const { tenantId, phoneNumberId, campaignId } = to.params
  const { threadId } = to.query
  const ids = [
    { id: tenantId, model: 'Tenant' },
    { id: phoneNumberId, model: 'PhoneNumber' },
    { id: campaignId, model: 'SmsCampaign' },
    { id: threadId, model: 'SmsThread' }
  ]
  const ids2Translate = ids.filter(id => id.id && isNumber(id.id))
  if (ids2Translate.length) {
    const translations = await Service.api.idTranslatorService?.translate(ids2Translate.map(id => ({ id: parseInt(id.id as any), model: id.model })))
    const { nonEmptyPathParams, nonEmptyQueryParams } = getNonEmptyParams(translations)
    useRouter().push({
      name: to.name as RouteRecordName,
      params: { ...to.params, ...nonEmptyPathParams },
      query: { ...to.query, ...nonEmptyQueryParams }
    })
  }
})

const getNonEmptyParams = (translations: Array<Translation>) => {
  const nonEmptyPathParams = Object.entries({
    tenantId: translations.find(id => id.model === 'Tenant')?.hashed_id,
    phoneNumberId: translations.find(id => id.model === 'PhoneNumber')?.hashed_id,
    campaignId: translations.find(id => id.model === 'SmsCampaign')?.hashed_id
  }).reduce((acc: any, [key, value]) => {
    if (value) {
      acc[key] = value
    }
    return acc
  }, {})
  const nonEmptyQueryParams = Object.entries({
    threadId: translations.find(id => id.model === 'SmsThread')?.hashed_id
  }).reduce((acc: any, [key, value]) => {
    if (value) {
      acc[key] = value
    }
    return acc
  }, {})
  return {
    nonEmptyPathParams,
    nonEmptyQueryParams
  }
}
