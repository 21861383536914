import { debounce } from 'lodash'

export enum SHORTCUT_COMBINATION {
  CTRLCMD_SHIFT_L = 'l',
  CTRLCMD_SHIFT_Y = 'y',
  CTRLCMD_SHIFT_U = 'u',
  CTRLCMD_SHIFT_F = 'f',
}
type Listener = (event: KeyboardEvent) => void
type ListenersPerShortcut = { [key in SHORTCUT_COMBINATION]?: Array<Listener> }

export class ShortcutHelper {
  static listenersPerShortcut: ListenersPerShortcut = {}

  static init () {
    const notifyListenersDebounced = debounce((event: KeyboardEvent) => { this.notifyListeners(event) }, 150)
    document.addEventListener('keydown', notifyListenersDebounced)
    this.listenersPerShortcut = {}
  }

  static subscribeTo (shortcut: SHORTCUT_COMBINATION, callback: Listener): void {
    if (!this.listenersPerShortcut[shortcut]) {
      this.listenersPerShortcut[shortcut] = []
    }
    this.listenersPerShortcut[shortcut]?.push(callback)
  }

  static unsubscribeFrom (shortcut: SHORTCUT_COMBINATION, callback: Listener): void {
    this.listenersPerShortcut[shortcut] = this.listenersPerShortcut[shortcut]?.filter(listener => listener !== callback)
  }

  static clearAllListenersFor (shortcut: SHORTCUT_COMBINATION): void {
    this.listenersPerShortcut[shortcut] = []
  }

  static notifyListeners (event: KeyboardEvent): void {
    const hasSpecialKeyPressed = (event.ctrlKey || event.metaKey) && event.shiftKey
    if (!hasSpecialKeyPressed) {
      return
    }
    const key = event.key?.toLowerCase()
    const listenersForKey = this.listenersPerShortcut[key as SHORTCUT_COMBINATION]
    if (listenersForKey?.length) {
      listenersForKey.forEach(listener => listener(event))
    }
  }
}
