import { computed } from 'vue'
import { useRoute } from 'nuxt/app'

export default () => {
  const route = useRoute()
  const path = computed(() => route.path)
  const tenantId = computed(() => route.params.tenantId)
  const phoneNumberId = computed(() => route.params.phoneNumberId)
  const threadId = computed(() => route.params.threadId)
  const campaignId = computed(() => route.params.campaignId)
  const eventId = computed(() => route.params.eventId)
  const hash = computed(() => route.params.hash)
  const query = computed(() => route.query)
  return {
    path,
    tenantId,
    phoneNumberId,
    threadId,
    campaignId,
    eventId,
    hash,
    query
  }
}
