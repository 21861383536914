const ALLOWED_ROUTES: Array<string> = ['login', 'logout', 'password-set-loginId-token', 'redirect', 'forgot-password', 'calendar-invitation-eventId', 'integrations-nylas-booking-bookingId']
const isPasswordSetRoute = (route: { hash: string }) => route.hash.startsWith('#/password-set')
export default defineNuxtRouteMiddleware((to) => {
  const router = useRouter()
  const { addExternalAuthLoginDeviceInfo } = useDeviceStore()
  // If the user is not authenticated
  const isPrivateRoute = !ALLOWED_ROUTES.includes(to.name as string)
  const authToken = localStorage.getItem('auth-token')
  const comesFromMobileToLogin = to.path.startsWith('/app-login')
  if (to.query.comesFromPlatform) {
    const comesFromPlatform = to.query.comesFromPlatform
    addExternalAuthLoginDeviceInfo(comesFromPlatform)
  }
  if (comesFromMobileToLogin && !authToken) {
    return router.push({ path: '/login', query: { ...to.query } })
  }
  if (isPasswordSetRoute(to)) {
    return navigateTo(to.hash.replace('#', ''))
  }
  if (isPrivateRoute && !authToken) {
    return navigateTo('/login')
  }
})
