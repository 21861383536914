import { render } from 'vue'
import AlertDialog from '@/components/organisms/dialogs/EmAlertDialog.vue'

type AlertType = 'error' | 'warning' | 'message' | 'info' | 'success' | null
type ShowNotificationOptions = {
  text: string
  title?: string
  type?: AlertType
  action?: () => void
  cancelAction?: () => void
  dialogProps?: any
}

export default () => {
  const showDialog = ({ text, type, title, action = () => {}, cancelAction = () => {}, dialogProps = {} }: ShowNotificationOptions) => {
    let componentInstance: any = null
    const nuxtApp = useNuxtApp()
    const mountEl = document.createElement('div')
    document.body.appendChild(mountEl)
    const alertComponent = h(AlertDialog, {
      destroy: () => {
        const parent = componentInstance?.$el?.parentNode
        render(null, parent)
        parent?.parentNode?.removeChild(parent)
      }
    })
    alertComponent.appContext = nuxtApp.vueApp._context
    render(alertComponent, mountEl)
    componentInstance = alertComponent.component?.proxy
    const { show } = alertComponent.component?.exposed as { show({ text, type, action, cancelAction, dialogProps }: ShowNotificationOptions): void }
    show({
      text,
      type,
      action,
      cancelAction,
      dialogProps: {
        title,
        ...dialogProps
      }
    })
  }
  const error = ({ text, title, action = () => {}, cancelAction = () => {}, dialogProps = {} }: ShowNotificationOptions) => {
    showDialog({ text, type: 'error', title, action, cancelAction, dialogProps })
  }
  const warning = ({ text, title, action = () => {}, cancelAction = () => {}, dialogProps = {} }: ShowNotificationOptions) => {
    showDialog({ text, type: 'warning', title, action, cancelAction, dialogProps })
  }
  const message = ({ text, title, action = () => {}, cancelAction = () => {}, dialogProps = {} }: ShowNotificationOptions) => {
    showDialog({ text, type: 'message', title, action, cancelAction, dialogProps })
  }
  const info = ({ text, title, action = () => {}, cancelAction = () => {}, dialogProps = {} }: ShowNotificationOptions) => {
    showDialog({ text, type: 'info', title, action, cancelAction, dialogProps })
  }
  const success = ({ text, title, action = () => {}, cancelAction = () => {}, dialogProps = {} }: ShowNotificationOptions) => {
    showDialog({ text, type: 'success', title, action, cancelAction, dialogProps })
  }
  const showWithNoAlert = ({ text, title, action = () => {}, cancelAction = () => {}, dialogProps = {} }: ShowNotificationOptions) => {
    showDialog({ text, type: null, title, action, cancelAction, dialogProps })
  }
  return {
    error,
    warning,
    message,
    info,
    success,
    showWithNoAlert
  }
}
