<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    location="top"
    :color="color"
    content-class="pa-0 mt-4"
    min-width="300px"
    class="em-snackbar fixed-safe-area--top"
  >
    <EmAlert
      v-model="snackbar"
      :type="color"
      class="ma-0"
      :class="{'cursor-pointer': snackbarAction}"
      :icon="icon"
      close-icon="mdi-close"
      data-cy="snackbar-notification"
      aria-label="snackbar-notification"
      dismissible
      :closable="closable"
      @click="executeSnackbarAction"
    >
      <div class="d-flex justify-space-between">
        <div>
          <div v-if="title">
            <EmBody2 bold>
              {{ title }}
            </EmBody2>
          </div>
          <div>
            <EmBody3>
              {{ text }}
            </EmBody3>
          </div>
        </div>
      </div>
    </EmAlert>
  </v-snackbar>
</template>
<script setup>
const snackbar = ref(false)
const text = ref('')
const title = ref('')
const type = ref('success')
const timeout = ref(-1)
const snackbarAction = ref(null)
const customIcon = ref(null)
const closable = ref(false)
const icon = computed(() => {
  return customIcon.value || (type.value === 'message' ? 'mdi-chat' : null)
})
const color = computed(() => {
  return type.value === 'message' ? 'info' : type.value
})
const show = ({ title: title2Show, text: text2Show, timeout: timeout2Show, type: type2Show, snackbarAction: snackbarAction2Show, icon, canClose = false }) => {
  title.value = title2Show
  text.value = text2Show
  type.value = type2Show
  timeout.value = timeout2Show || timeout.value
  snackbar.value = true
  snackbarAction.value = snackbarAction2Show
  customIcon.value = icon
  closable.value = canClose
}
const close = () => {
  snackbar.value = false
}
const executeSnackbarAction = () => {
  if (snackbarAction.value) {
    snackbarAction.value()
    close()
  }
}
const emits = defineEmits(['destroy'])
watch(snackbar, (val) => {
  if (!val) {
    emits('destroy')
  }
})
defineExpose({
  show,
  close
})
</script>
<style lang="scss" scoped>
.em-snackbar :deep(.v-snack__action) {
  margin-right: 0px;
}
.em-snackbar :deep(.v-snack__wrapper) {
  border-radius: 8px;
  display: flex;
}
.em-snackbar :deep(.v-alert__dismissible) {
  margin: 0;
}
.em-snackbar :deep(.v-alert__icon) {
  margin-top: 0px;
  margin-right: 4px;
  align-self: center;
}
.em-snackbar :deep(.v-snackbar__content){
  padding: 8px 0px;
}
</style>
