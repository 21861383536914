import { useUserStore } from '@/stores/user'
const REFRESH_TOKEN_INTERVAL = 2 * 60 * 1000
const PING_INTERVAL = 60 * 1000
const CHECK_MEMORY_INTERVAL = 60 * 1000

export default defineNuxtPlugin(() => {
  const user = useUserStore()
  setInterval(() => {
    user.refreshUserToken()
  }, REFRESH_TOKEN_INTERVAL)

  setInterval(() => {
    user.getUserInformation()
  }, PING_INTERVAL)
  const { checkMemoryUsage } = useReportsStore()
  setInterval(() => {
    checkMemoryUsage()
  }, CHECK_MEMORY_INTERVAL)
})
