import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { get, set } from 'lodash'

interface ErrorResponse extends AxiosError {
    errorMsg: string,
    errors: Array<string>
}

interface ErrorData {
    error?: string,
    detail?: string,
    reason?: string
}

export default defineNuxtPlugin((_nuxtApp: any) => {
  const defaultUrl = (<string>import.meta.env.VITE_API_ENDPOINT)

  const http = axios.create({
    baseURL: defaultUrl,
    headers: {
      common: {
        'Content-Language': 'en',
        'X-Ignore-Session': true,
        Authorization: ''
      }
    }
  })

  const requestInterceptor = {
    onFulfilled: (config: InternalAxiosRequestConfig) => {
      if (
        import.meta.env.ENVIROMENT === 'development' &&
        http.defaults.baseURL === '/'
      ) {
        config.url = `/api${config.url}`
      }
      const token = localStorage.getItem('auth-token')
      if (token) {
        config.headers!.Authorization = `JWT ${token}`
      }
      return config
    },
    onRejected: (error: AxiosError) => {
      throw error
    }
  }

  const responseInterceptor = {
    onFulfilled: (response: AxiosResponse) => {
      if (get(response, 'status') === 403 || get(response, 'status') === 401) {
        return navigateTo('/logout') as Promise<void | any>
      }
      return response
    },
    onRejected: (error: ErrorResponse) => {
      const route = useRoute()
      const { response } = error
      const data = (response as AxiosResponse<ErrorData>)?.data
      const code = get(response, 'status', 0)
      const has2SkipRedirection = route.name === 'login'
      const has2Redirect = code === 401 || data?.detail === 'Error decoding token.'
      const has2RedirectToStatus = data?.reason === 'suspended' && route.name !== 'logout'
      if (has2Redirect && !has2SkipRedirection) {
        if (has2RedirectToStatus) {
          navigateTo('/account-status')
        } else {
          navigateTo({
            path: '/logout/',
            query: route?.query
          })
        }
      } else if (code === 403 && !has2SkipRedirection) {
        navigateTo('/invalid-url')
      } else if (code === 400) {
        const errors = data ? Object.values(data)?.flat() : []
        error.errorMsg = errors?.join(', ')
        error.errors = errors
      } else {
        error.errors = []
        const errorMessage = data?.error
        if (errorMessage) {
          error.errors.push(errorMessage)
        }
      }
      throw error
    }
  }

  http.interceptors.request.use(
    requestInterceptor.onFulfilled,
    requestInterceptor.onRejected
  )

  http.interceptors.response.use(
    responseInterceptor.onFulfilled,
    responseInterceptor.onRejected
  )

  set(http, '$get', (url: string, ...params: any[]) => {
    return http.get(url, ...params).then(response => response.data)
  })

  set(http, '$post', (url: string, ...params: any[]) => {
    return http.post(url, ...params).then(response => response.data)
  })

  set(http, '$patch', (url: string, ...params: any[]) => {
    return http.patch(url, ...params).then(response => response.data)
  })

  set(http, '$delete', (url: string, ...params: any[]) => {
    return http.delete(url, ...params).then(response => response.data)
  })

  return {
    provide: {
      axios: http
    }
  }
})
