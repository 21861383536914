<template>
  <v-app dark class="bg-bgprimary">
    <div class="height-100 d-flex justify-center align-center">
      <EmEmptyState
        v-if="error.statusCode === 404"
        icon="mdi-robot-confused"
        title="404 Not Found"
        subtitle="The page you are looking for doesn't exist."
        btn-text="Go to home"
        :loading="loading"
        secondary-btn-text="Logout"
        @secondary-click="logout"
        @click="goToHome()"
      />
      <EmEmptyState
        v-else
        icon="mdi-cog"
        title="An unexpected error occurred"
        btn-text="Go to home"
        secondary-btn-text="Logout"
        :loading="loading"
        @secondary-click="logout"
        @click="goToHome()"
      >
        <template #subtitle>
          <div class="text-center">
            <EmBody1>
              If this error persist, please
              <EmSupportEmail>
                contact us
              </EmSupportEmail>
            </EmBody1>
          </div>
          <EmErrorStack :show="showError" :error="error" />
        </template>
      </EmEmptyState>
    </div>
  </v-app>
</template>

<script setup>
import * as Sentry from '@sentry/browser'
const props = defineProps({
  error: { type: Object, default: null }
})
useHead({
  title: props.error.statusCode === 404 ? 'Not found' : 'Unexpected error'
})
const router = useRouter()
const user = useUserStore()
const loading = ref(false)
const logout = () => { router.push('/logout') }
const goToHome = async () => {
  loading.value = true
  await user.goToInboxOrHome()
  loading.value = false
}
onMounted(() => {
  Sentry.captureException(props.error)
})
</script>
