export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico","hid":"icon"}],"style":[],"script":[{"src":"https://media.twiliocdn.com/sdk/js/conversations/v2.4/twilio-conversations.min.js"},{"src":"https://cdn.jsdelivr.net/npm/@nylas/web-elements@1.1.4/dist/cdn/nylas-scheduling/nylas-scheduling.es.js","type":"module"},{"src":"https://cdn.jsdelivr.net/npm/@nylas/web-elements@latest/dist/cdn/nylas-availability-picker/nylas-availability-picker.es.js","type":"module"}],"noscript":[],"titleTemplate":"%s - Emissary","title":"Emissary","htmlAttrs":{"lang":"en"}}

export const appPageTransition = {"name":"page","mode":"default"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'