import { DateTime, Duration, DurationObjectUnits } from 'luxon'
import { useTimeZoneStore } from '@/stores/timeZone'

export default () => {
  const DATE_TIME_FORMAT_TOKEN: Record<string, string> = {
    isoDate: 'yyyy-LL-dd',
    isoTime: 'HH:mm:ss',
    isoDateTime: "yyyy-LL-dd'T'HH:mm:ss",
    monthAndYear: 'LLL yyyy'
  }
  const toISODateTime = ({ date, time }: {date: string, time: string}): string => {
    return `${date}T${time}`
  }
  const formatDateTime = (dateTime: DateTime, toFormat = 'isoDateTime'): string => {
    const timeZoneStore = useTimeZoneStore()
    if (toFormat === 'userDate') {
      return dateTime.toFormat(timeZoneStore.format.date)
    }
    if (toFormat === 'userTime') {
      return dateTime.toFormat(timeZoneStore.format.time)
    }
    if (toFormat === 'userDateTime') {
      return dateTime.toFormat(`${timeZoneStore.format.date} 'at' ${timeZoneStore.format.time}`)
    }
    return dateTime.toFormat(DATE_TIME_FORMAT_TOKEN[toFormat])
  }
  const formatDateTimeFromISO = ({ iso, toFormat }: { iso: string, toFormat: string }): string => {
    const date = DateTime.fromISO(iso)
    return formatDateTime(date, toFormat)
  }
  const formatDateFriendly = ({ timeZone, iso }: { timeZone: string, iso: string }): string => {
    const { getDifference, toISODateTime } = useTimeAux()
    const timeZoneStore = useTimeZoneStore()
    const isoStart = toISODateTime({
      date: formatDateTimeFromISO({ iso, toFormat: 'isoDate' }),
      time: '00:00:00'
    })
    const isoEnd = toISODateTime({
      date: timeZoneStore.getUserDateTimeNow({ timeZone, toFormat: 'isoDate' }),
      time: '00:00:00'
    })
    const differenceInDays = Number(getDifference({ isoStart, isoEnd, toFormat: 'days' }))
    if (differenceInDays < 1) {
      return 'Today'
    } else if (differenceInDays < 2) {
      return 'Yesterday'
    } else {
      return formatDateTimeFromISO({ iso, toFormat: 'userDate' })
    }
  }
  const formatDurationFriendly = (duration: Duration): string => {
    const years = duration.years
    const months = duration.months
    const days = duration.days
    const hours = duration.hours
    const minutes = duration.minutes
    const seconds = duration.seconds
    const pluralOrSingular = (value: number, unit: string) => {
      return value === 1 ? `${value} ${unit}` : `${value} ${unit}s`
    }
    if (years > 0) {
      return `more than ${pluralOrSingular(years, 'year')}`
    } else if (months > 0) {
      return `${pluralOrSingular(months, 'month')} and ${pluralOrSingular(days, 'day')}`
    } else if (days > 0) {
      return `${pluralOrSingular(days, 'day')} and ${pluralOrSingular(hours, 'hour')}`
    } else if (hours > 0) {
      return `${pluralOrSingular(hours, 'hour')} and ${pluralOrSingular(minutes, 'minute')}`
    } else if (minutes > 0) {
      return `${pluralOrSingular(minutes, 'minute')} and ${pluralOrSingular(seconds, 'second')}`
    } else {
      return pluralOrSingular(seconds, 'second')
    }
  }
  const DURATION_FORMAT_TOKEN: Record<string, string> = {
    duration: "hh'h' mm'm' ss's'",
    years: 'yyyy',
    months: 'LL',
    days: 'dd',
    hours: 'hh',
    minutes: 'mm',
    seconds: 'ss'
  }
  const formatDuration = (duration: Duration, toFormat = 'duration'): DurationObjectUnits | string => {
    if (toFormat === 'object') {
      return duration.toObject()
    }
    if (toFormat === 'friendly') {
      return formatDurationFriendly(duration)
    }
    return duration.toFormat(DURATION_FORMAT_TOKEN[toFormat])
  }
  const formatMonth = (date: string) => formatDateTimeFromISO({
    iso: toISODateTime({ date, time: '00:00:00' }),
    toFormat: 'monthAndYear'
  })

  return {
    formatDateTime,
    formatDateTimeFromISO,
    formatMonth,
    formatDateFriendly,
    formatDuration,
    toISODateTime
  }
}
