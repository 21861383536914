import { ShortcutHelper } from '@/services/helpers/ShortcutHelper'
export default defineNuxtPlugin(() => {
  const user = useUserStore()
  const token = localStorage.getItem('auth-token')
  if (token) {
    user.editable.token = token
  }
  const currentTenant = localStorage.getItem('current-tenant')
  if (currentTenant) {
    user.editable.currentTenantId = currentTenant
  }
  const { isMobileDevice, isAppOrWebWithMobile, addDeepLinking, inviteToDownloadIfMobileBrowser } = useDeviceStore()
  isMobileDevice()
  isAppOrWebWithMobile()
  addDeepLinking()
  ShortcutHelper.init()
  inviteToDownloadIfMobileBrowser()
})
