import * as Sentry from '@sentry/browser'

export default defineNuxtPlugin((_nuxtApp: any) => {
  if (!import.meta.env.VITE_SENTRY_DSN) {
    // eslint-disable-next-line no-console
    console.log('No Sentry DSN provided, skipping Sentry initialization.')
    return
  }
  if (import.meta.env.VITE_SENTRY_ENABLED !== 'true') {
    // eslint-disable-next-line no-console
    console.log('Sentry is disabled, skipping Sentry initialization.')
    return
  }
  Sentry.init({
    beforeSend (event) {
      // eslint-disable-next-line no-console
      console.log('[Sentry] beforeSend')
      // eslint-disable-next-line no-console
      console.log(event)
      return event
    },
    dsn: <string>import.meta.env.VITE_SENTRY_DSN,
    debug: import.meta.env.VITE_ENVIROMENT === 'development',
    environment: <string>import.meta.env.VITE_ENVIROMENT,
    release: <string>import.meta.env.VITE_VUE_APP_VERSION,
    dist: 'dist-' + <string>import.meta.env.VITE_VUE_APP_VERSION,
    autoSessionTracking: false,
    ignoreErrors: [
      'Error: Network Error',
      'Error: Uncaught, unspecified \'error\' event. (false)',
      'Error: Uncaught, unspecified "error" event. (false)',
      'Error: Forbidden',
      'TypeError: Cannot create property \'normalizeDepth\' on string \'**non-serializable**\'',
      'Cannot create property \'normalizeDepth\' on string \'**non-serializable**\'',
      'NotAllowedError: play() failed because the user didn\'t interact with the document first. https://goo.gl/xX8pDD',
      'NotSupportedError: Failed to load because no supported source was found.',
      'Non-Error exception captured with keys: details, error',
      'Error: Request failed with status code 403',
      'Error: GraphQL error: Unauthorized request',
      'Error: Request failed with status code 504',
      'Error: ResizeObserver loop limit exceeded',
      'Error: timeout of 0ms exceeded',
      'n: disconnected (status: 0, code: 0)',
      'Error: GraphQL error: Invalid payload',
      'Error: disconnected',
      'Error: Transport failure: UNAUTHORIZED',
      'Error: Transport failure: UPSTREAM_REQUEST_TIMEOUT',
      'n: Access forbidden for identity (status: 403, code: 54007)',
      'n: request \'GET\' to \'cds.us1.twilio.com\' timed out (status: 0, code: 0)',
      'ResizeObserver loop limit exceeded',
      'ReportingObserver [deprecation]: Permission for the Notification API may no longer be requested from a cross-origin iframe. You should consider requesting permission from a top-level frame or opening a new window instead. See https://www.chromestatus...',
      'Error: Unable to connect: Connection is not initialized',
      'Error: Transport failure: UPSTREAM_REQUEST_TIMEOUT',
      'Error: Unable to connect: disconnected',
      /(Twilsock: request timeout:)+/g,
      'n: Transport failure: CPS_UPSTREAM_PER_CONNECTION (status: 0, code: 0)',
      'Error: Network error: Failed to fetchNetwork error: Failed to fetch',
      'InvalidStateError: Failed to execute \'send\' on \'WebSocket\': Still in CONNECTING state.Failed to execute',
      'TypeError: Cannot create property \'normalizeDepth\' on string \'**non-serializable**\'Cannot create property \'normalizeDepth\' on string \'**non-serializable**\'',
      'TypeError: Cannot read properties of null (reading \'data_onhidden\')',
      'GraphQL error: Error decoding signatureGraphQL error: Error decoding signature',
      'AxiosError: Request failed with status code 401',
      'AxiosError: timeout exceeded',
      'Uncaught SyntaxError: Unexpected token \'export\' (at nylas-scheduling.es.js:27516:1)',
      'Uncaught SyntaxError: Unexpected token \'export\' (at nylas-availability-picker.es.js:10802:1)'
    ],
    normalizeDepth: 6,
    tracesSampleRate: 0.4
    // replaysOnErrorSampleRate: 1.0
    // integrations: [
    //   new Sentry.Replay({
    //     maskAllText: true,
    //     blockAllMedia: true
    //   })
    // ]
    // Add native Sentry config here
    // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/options/
    // To set a uniform sample rate
    // tracing: {
    //   vueOptions: {
    //     tracing: true,
    //     tracingOptions: {
    //       hooks: ['mount', 'update'],
    //       timeout: 2000,
    //       trackComponents: true
    //     },
    //   }
    // },
    // clientIntegrations: {
    //   Vue: { attachProps: true, logErrors: true }
    // }
  })
})
