/* eslint-disable no-console */
import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  if (!import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID) {
    console.warn('Missing VITE_GOOGLE_TAG_MANAGER_ID env variable so GTM will not be loaded.')
    return
  }
  nuxtApp.vueApp.use(createGtm({
    id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
    defer: false, // Script can be set to `defer` to speed up page load
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    enabled: true, // If disabled, no data will be send to GTM
    debug: import.meta.env.VITE_ENVIROMENT === 'development', // If enabled, will emit events on the browser's console
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality. Disables GTM from loading)
    vueRouter: useRouter(),
    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
  }))
})
