import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
// make sure to also import the coresponding css

const colors = {
  error: '#E15F53',
  success: '#61C276',
  info: '#49A0AB',
  'primary-lighten-5': '#b5edf5',
  'primary-lighten-4': '#24828F',
  'primary-lighten-3': '#4b8692',
  'primary-lighten-2': '#336e7a',
  'primary-lighten-1': '#548991',
  primary: '#2C6068',
  'primary-darken-1': '#00363A',
  'primary-darken-2': '#002D30',
  bgprimary: '#F5F9FA',
  'bgprimary-darken-1': '#E2F0F2',
  secondary: '#9E9278',
  'secondary-darken-1': '#716751',
  'bgsecondary-lighten-2': '#F0E9DB',
  'bgsecondary-lighten-1': '#D9F0DE',
  bgsecondary: '#F6F2E9',
  'bgsecondary-darken-1': '#E0D6BF',
  'bgsecondary-darken-2': '#E6DECC',
  bggrey: '#E7EAEB',
  'bggrey-lighten-1': '#C2CBCF',
  'neutral-lighten-4': '#A7C6CA',
  'neutral-lighten-3': '#7F9A9E',
  'neutral-lighten-2': '#547377',
  'neutral-lighten-1': '#4A6569',
  neutral: '#133539',
  warning: '#F78C2C',
  'warning-darken-1': '#854105',
  starred: '#FBC02D',
  unread: '#CC6666',
  avatar1: '#ED9468',
  avatar2: '#EEAD52',
  avatar3: '#F1CB59',
  avatar4: '#EBDE4E',
  avatar5: '#98D66B',
  avatar6: '#77C07B',
  avatar7: '#66B494',
  avatar8: '#75CED1',
  avatar9: '#69ACCF',
  avatar10: '#6492E6',
  avatar11: '#6381F7',
  avatar12: '#7574EB',
  avatar13: '#AC7FF7',
  avatar14: '#CA72E7',
  avatar15: '#EE83DB',
  avatar16: '#E27B9E'
}

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    ssr: false,
    components,
    directives,
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: { mdi }
    },
    theme: {
      defaultTheme: 'light',
      themes: {
        dark: {
          dark: true,
          colors
        },
        light: {
          dark: false,
          colors
        }
      }
    }
  })
  nuxtApp.vueApp.use(vuetify)
})
