import { default as indexAxqfOcedwWMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/[tenantId]/[phoneNumberId]/contacts/index.vue?macro=true";
import { default as indexNSfA8Xt22zMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/[tenantId]/[phoneNumberId]/inbox/[threadId]/index.vue?macro=true";
import { default as indexbPXRWipGZAMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/[tenantId]/[phoneNumberId]/inbox/index.vue?macro=true";
import { default as _91campaignId_93VMrabZjDiTMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/[tenantId]/[phoneNumberId]/sms-campaigns/[campaignId].vue?macro=true";
import { default as indexr9cKVWXmewMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/[tenantId]/[phoneNumberId]/sms-campaigns/index.vue?macro=true";
import { default as stats9yoAsuyIM5Meta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/[tenantId]/stats.vue?macro=true";
import { default as account_45statusj3fnocCCICMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/account-status.vue?macro=true";
import { default as app_45login6VK2Y8VxCBMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/app-login.vue?macro=true";
import { default as _91eventId_93vzXrvDa38PMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/calendar-invitation/[eventId].vue?macro=true";
import { default as authg8jCulZ2bxMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/calendar/auth.vue?macro=true";
import { default as indexWcTWanQaAiMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/calendar/index.vue?macro=true";
import { default as testyV91X7o759Meta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/calendar/test.vue?macro=true";
import { default as indexdzLWlsoCKoMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/chrome-extension/index.vue?macro=true";
import { default as indexUC8bcfMVmzMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/contacts/[tenantId]/[phoneNumberId]/index.vue?macro=true";
import { default as indexp0dCsMWbtnMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/download-pdf/[hash]/index.vue?macro=true";
import { default as create1PrJUqSAfjMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/drip-campaigns/[tenantId]/[phoneNumberId]/[campaignId]/create.vue?macro=true";
import { default as index5mFl9Fr43TMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/drip-campaigns/[tenantId]/[phoneNumberId]/[campaignId]/index.vue?macro=true";
import { default as updateMessagesRSlzeW18bnMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/drip-campaigns/[tenantId]/[phoneNumberId]/[campaignId]/updateMessages.vue?macro=true";
import { default as updateRecipientsxN3PQd90XiMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/drip-campaigns/[tenantId]/[phoneNumberId]/[campaignId]/updateRecipients.vue?macro=true";
import { default as indexu5C6CQhRlDMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/drip-campaigns/[tenantId]/[phoneNumberId]/index.vue?macro=true";
import { default as calendarPtrW19W49pMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/early-access/calendar.vue?macro=true";
import { default as drip_45campaignszw6UiiSkJrMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/early-access/drip-campaigns.vue?macro=true";
import { default as voice_45callsMvwviFPLgdMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/early-access/voice-calls.vue?macro=true";
import { default as whatsapp_45messagingRgr3qqzNlHMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/early-access/whatsapp-messaging.vue?macro=true";
import { default as forgot_45passwordSD7stX1k4CMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/forgot-password.vue?macro=true";
import { default as inbox_45redirectZ6bxooKiJBMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/inbox-redirect.vue?macro=true";
import { default as indexHUp1zptPbXMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/inbox/[tenantId]/[phoneNumberId]/index.vue?macro=true";
import { default as alll2VhnZEjT8Meta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/inbox/all.vue?macro=true";
import { default as indexE6Ktkgcf24Meta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/index.vue?macro=true";
import { default as indexhYZ9NKRMBlMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/integrations/[tenantId]/index.vue?macro=true";
import { default as _91bookingId_93HXdxHmhwHuMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/integrations/nylas/booking/[bookingId].vue?macro=true";
import { default as nylas_45oauth_45handshakePQO7z0rVJrMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/integrations/nylas/nylas-oauth-handshake.vue?macro=true";
import { default as invalid_45permissionsO8dbeBOp4fMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/invalid-permissions.vue?macro=true";
import { default as invalid_45url3llvEm9ZroMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/invalid-url.vue?macro=true";
import { default as loginRJVap3EXlUMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/login.vue?macro=true";
import { default as logoutbcUwsM9BD9Meta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/logout.vue?macro=true";
import { default as _91tenantId_93knf6WSVX5qMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/metrics/messages-campaigns/[tenantId].vue?macro=true";
import { default as updatecI4Oppca8XMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/oneoff-campaigns/[tenantId]/[phoneNumberId]/[campaignId]/update.vue?macro=true";
import { default as createPdgkrtsVI5Meta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/oneoff-campaigns/[tenantId]/[phoneNumberId]/create.vue?macro=true";
import { default as indexOIYy7YJlJ3Meta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/oneoff-campaigns/[tenantId]/[phoneNumberId]/index.vue?macro=true";
import { default as indexDMNp27SZ6TMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/password-set/[loginId]/[token]/index.vue?macro=true";
import { default as redirectjoSUVrdyOWMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/redirect.vue?macro=true";
import { default as indexiYbVvssV39Meta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/seats/[tenantId]/index.vue?macro=true";
import { default as index2HFWYPcSDJMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/shared-templates/[tenantId]/index.vue?macro=true";
import { default as autocompletekW22AqDwCjMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/autocomplete.vue?macro=true";
import { default as breadcrumbsIIFUMHWU4vMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/breadcrumbs.vue?macro=true";
import { default as buttons32Urn0dCvNMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/buttons.vue?macro=true";
import { default as dividere8V2kxojUEMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/divider.vue?macro=true";
import { default as headerIG4jRpQrsYMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/grids/header.vue?macro=true";
import { default as no_45headerRXh73WoJVcMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/grids/no-header.vue?macro=true";
import { default as imagesRcoUs7ox8UMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/images.vue?macro=true";
import { default as inputsjbcZPCF7LSMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/inputs.vue?macro=true";
import { default as listswbXvkpa2UDMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/lists.vue?macro=true";
import { default as onboarding09M66UPy4UMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/onboarding.vue?macro=true";
import { default as tabsVlfEbsGPaIMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/tabs.vue?macro=true";
import { default as typographiesEQukClWBNhMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/typographies.vue?macro=true";
import { default as avatarsQnLxFxlhuCMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/avatars.vue?macro=true";
import { default as chatVtD5yVcpsbMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/chat.vue?macro=true";
import { default as cookiesQd1EQSdXmfMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/cookies.vue?macro=true";
import { default as flagsfvCPwIiVplMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/flags.vue?macro=true";
import { default as kpisy9s9BzTJiwMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/kpis.vue?macro=true";
import { default as listMUI1TlEcFuMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/list.vue?macro=true";
import { default as logosntQ1Tpkm3BMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/logos.vue?macro=true";
import { default as menusVXEqdwVGJmMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/menus.vue?macro=true";
import { default as seatvkTQa5TNjTMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/seat.vue?macro=true";
import { default as tablesOeMhmKNduuMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/tables.vue?macro=true";
import { default as templatesWm29mJJ0fDMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/templates.vue?macro=true";
import { default as textsDXDOqm151XMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/texts.vue?macro=true";
import { default as urlShortener82oGVhq5eSMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/urlShortener.vue?macro=true";
import { default as variableswxuZO2szGPMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/variables.vue?macro=true";
import { default as chips_45and_45tagsnBLPNONz29Meta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/chips-and-tags.vue?macro=true";
import { default as chrome_45extensionkAyvGXJrvCMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/chrome-extension.vue?macro=true";
import { default as errorsvL3hKOlaxRMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/errors.vue?macro=true";
import { default as indexzjTovjeqipMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/index.vue?macro=true";
import { default as local_45storage97z8gofIsnMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/local-storage.vue?macro=true";
import { default as buttonsQWNFZnomUSMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/buttons.vue?macro=true";
import { default as footers761Ab0hTLTMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/footers.vue?macro=true";
import { default as loadingYLTaRXrpdeMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/loading.vue?macro=true";
import { default as menusRoTu0kGtPaMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/menus.vue?macro=true";
import { default as paginationiaWMX5kc75Meta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/pagination.vue?macro=true";
import { default as stepperZiZA5iv1xxMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/stepper.vue?macro=true";
import { default as text7LpGGbacXzMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/text.vue?macro=true";
import { default as transitionsWcsUbUJATmMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/transitions.vue?macro=true";
import { default as piniaecnnyM5gpmMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/test/pinia.vue?macro=true";
import { default as user_45preferencesmEdISlIsJsMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/user-preferences.vue?macro=true";
import { default as indexFbHVU33VTbMeta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/whatsapp-templates/[tenantId]/[phoneNumberId]/index.vue?macro=true";
import { default as onboardavrQCtjWc5Meta } from "/home/runner/work/emissary-webgui/emissary-webgui/pages/whatsapp/onboard.vue?macro=true";
export default [
  {
    name: indexAxqfOcedwWMeta?.name ?? "tenantId-phoneNumberId-contacts",
    path: indexAxqfOcedwWMeta?.path ?? "/:tenantId()/:phoneNumberId()/contacts",
    meta: indexAxqfOcedwWMeta || {},
    alias: indexAxqfOcedwWMeta?.alias || [],
    redirect: indexAxqfOcedwWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/[tenantId]/[phoneNumberId]/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexNSfA8Xt22zMeta?.name ?? "tenantId-phoneNumberId-inbox-threadId",
    path: indexNSfA8Xt22zMeta?.path ?? "/:tenantId()/:phoneNumberId()/inbox/:threadId()",
    meta: indexNSfA8Xt22zMeta || {},
    alias: indexNSfA8Xt22zMeta?.alias || [],
    redirect: indexNSfA8Xt22zMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/[tenantId]/[phoneNumberId]/inbox/[threadId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbPXRWipGZAMeta?.name ?? "tenantId-phoneNumberId-inbox",
    path: indexbPXRWipGZAMeta?.path ?? "/:tenantId()/:phoneNumberId()/inbox",
    meta: indexbPXRWipGZAMeta || {},
    alias: indexbPXRWipGZAMeta?.alias || [],
    redirect: indexbPXRWipGZAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/[tenantId]/[phoneNumberId]/inbox/index.vue").then(m => m.default || m)
  },
  {
    name: _91campaignId_93VMrabZjDiTMeta?.name ?? "tenantId-phoneNumberId-sms-campaigns-campaignId",
    path: _91campaignId_93VMrabZjDiTMeta?.path ?? "/:tenantId()/:phoneNumberId()/sms-campaigns/:campaignId()",
    meta: _91campaignId_93VMrabZjDiTMeta || {},
    alias: _91campaignId_93VMrabZjDiTMeta?.alias || [],
    redirect: _91campaignId_93VMrabZjDiTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/[tenantId]/[phoneNumberId]/sms-campaigns/[campaignId].vue").then(m => m.default || m)
  },
  {
    name: indexr9cKVWXmewMeta?.name ?? "tenantId-phoneNumberId-sms-campaigns",
    path: indexr9cKVWXmewMeta?.path ?? "/:tenantId()/:phoneNumberId()/sms-campaigns",
    meta: indexr9cKVWXmewMeta || {},
    alias: indexr9cKVWXmewMeta?.alias || [],
    redirect: indexr9cKVWXmewMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/[tenantId]/[phoneNumberId]/sms-campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: stats9yoAsuyIM5Meta?.name ?? "tenantId-stats",
    path: stats9yoAsuyIM5Meta?.path ?? "/:tenantId()/stats",
    meta: stats9yoAsuyIM5Meta || {},
    alias: stats9yoAsuyIM5Meta?.alias || [],
    redirect: stats9yoAsuyIM5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/[tenantId]/stats.vue").then(m => m.default || m)
  },
  {
    name: account_45statusj3fnocCCICMeta?.name ?? "account-status",
    path: account_45statusj3fnocCCICMeta?.path ?? "/account-status",
    meta: account_45statusj3fnocCCICMeta || {},
    alias: account_45statusj3fnocCCICMeta?.alias || [],
    redirect: account_45statusj3fnocCCICMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/account-status.vue").then(m => m.default || m)
  },
  {
    name: app_45login6VK2Y8VxCBMeta?.name ?? "app-login",
    path: app_45login6VK2Y8VxCBMeta?.path ?? "/app-login",
    meta: app_45login6VK2Y8VxCBMeta || {},
    alias: app_45login6VK2Y8VxCBMeta?.alias || [],
    redirect: app_45login6VK2Y8VxCBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/app-login.vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93vzXrvDa38PMeta?.name ?? "calendar-invitation-eventId",
    path: _91eventId_93vzXrvDa38PMeta?.path ?? "/calendar-invitation/:eventId()",
    meta: _91eventId_93vzXrvDa38PMeta || {},
    alias: _91eventId_93vzXrvDa38PMeta?.alias || [],
    redirect: _91eventId_93vzXrvDa38PMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/calendar-invitation/[eventId].vue").then(m => m.default || m)
  },
  {
    name: authg8jCulZ2bxMeta?.name ?? "calendar-auth",
    path: authg8jCulZ2bxMeta?.path ?? "/calendar/auth",
    meta: authg8jCulZ2bxMeta || {},
    alias: authg8jCulZ2bxMeta?.alias || [],
    redirect: authg8jCulZ2bxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/calendar/auth.vue").then(m => m.default || m)
  },
  {
    name: indexWcTWanQaAiMeta?.name ?? "calendar",
    path: indexWcTWanQaAiMeta?.path ?? "/calendar",
    meta: indexWcTWanQaAiMeta || {},
    alias: indexWcTWanQaAiMeta?.alias || [],
    redirect: indexWcTWanQaAiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: testyV91X7o759Meta?.name ?? "calendar-test",
    path: testyV91X7o759Meta?.path ?? "/calendar/test",
    meta: testyV91X7o759Meta || {},
    alias: testyV91X7o759Meta?.alias || [],
    redirect: testyV91X7o759Meta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/calendar/test.vue").then(m => m.default || m)
  },
  {
    name: indexdzLWlsoCKoMeta?.name ?? "chrome-extension",
    path: indexdzLWlsoCKoMeta?.path ?? "/chrome-extension",
    meta: indexdzLWlsoCKoMeta || {},
    alias: indexdzLWlsoCKoMeta?.alias || [],
    redirect: indexdzLWlsoCKoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/chrome-extension/index.vue").then(m => m.default || m)
  },
  {
    name: indexUC8bcfMVmzMeta?.name ?? "contacts-tenantId-phoneNumberId",
    path: indexUC8bcfMVmzMeta?.path ?? "/contacts/:tenantId()/:phoneNumberId()",
    meta: indexUC8bcfMVmzMeta || {},
    alias: indexUC8bcfMVmzMeta?.alias || [],
    redirect: indexUC8bcfMVmzMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/contacts/[tenantId]/[phoneNumberId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexp0dCsMWbtnMeta?.name ?? "download-pdf-hash",
    path: indexp0dCsMWbtnMeta?.path ?? "/download-pdf/:hash()",
    meta: indexp0dCsMWbtnMeta || {},
    alias: indexp0dCsMWbtnMeta?.alias || [],
    redirect: indexp0dCsMWbtnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/download-pdf/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: create1PrJUqSAfjMeta?.name ?? "drip-campaigns-tenantId-phoneNumberId-campaignId-create",
    path: create1PrJUqSAfjMeta?.path ?? "/drip-campaigns/:tenantId()/:phoneNumberId()/:campaignId()/create",
    meta: create1PrJUqSAfjMeta || {},
    alias: create1PrJUqSAfjMeta?.alias || [],
    redirect: create1PrJUqSAfjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/drip-campaigns/[tenantId]/[phoneNumberId]/[campaignId]/create.vue").then(m => m.default || m)
  },
  {
    name: index5mFl9Fr43TMeta?.name ?? "drip-campaigns-tenantId-phoneNumberId-campaignId",
    path: index5mFl9Fr43TMeta?.path ?? "/drip-campaigns/:tenantId()/:phoneNumberId()/:campaignId()",
    meta: index5mFl9Fr43TMeta || {},
    alias: index5mFl9Fr43TMeta?.alias || [],
    redirect: index5mFl9Fr43TMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/drip-campaigns/[tenantId]/[phoneNumberId]/[campaignId]/index.vue").then(m => m.default || m)
  },
  {
    name: updateMessagesRSlzeW18bnMeta?.name ?? "drip-campaigns-tenantId-phoneNumberId-campaignId-updateMessages",
    path: updateMessagesRSlzeW18bnMeta?.path ?? "/drip-campaigns/:tenantId()/:phoneNumberId()/:campaignId()/updateMessages",
    meta: updateMessagesRSlzeW18bnMeta || {},
    alias: updateMessagesRSlzeW18bnMeta?.alias || [],
    redirect: updateMessagesRSlzeW18bnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/drip-campaigns/[tenantId]/[phoneNumberId]/[campaignId]/updateMessages.vue").then(m => m.default || m)
  },
  {
    name: updateRecipientsxN3PQd90XiMeta?.name ?? "drip-campaigns-tenantId-phoneNumberId-campaignId-updateRecipients",
    path: updateRecipientsxN3PQd90XiMeta?.path ?? "/drip-campaigns/:tenantId()/:phoneNumberId()/:campaignId()/updateRecipients",
    meta: updateRecipientsxN3PQd90XiMeta || {},
    alias: updateRecipientsxN3PQd90XiMeta?.alias || [],
    redirect: updateRecipientsxN3PQd90XiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/drip-campaigns/[tenantId]/[phoneNumberId]/[campaignId]/updateRecipients.vue").then(m => m.default || m)
  },
  {
    name: indexu5C6CQhRlDMeta?.name ?? "drip-campaigns-tenantId-phoneNumberId",
    path: indexu5C6CQhRlDMeta?.path ?? "/drip-campaigns/:tenantId()/:phoneNumberId()",
    meta: indexu5C6CQhRlDMeta || {},
    alias: indexu5C6CQhRlDMeta?.alias || [],
    redirect: indexu5C6CQhRlDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/drip-campaigns/[tenantId]/[phoneNumberId]/index.vue").then(m => m.default || m)
  },
  {
    name: calendarPtrW19W49pMeta?.name ?? "early-access-calendar",
    path: calendarPtrW19W49pMeta?.path ?? "/early-access/calendar",
    meta: calendarPtrW19W49pMeta || {},
    alias: calendarPtrW19W49pMeta?.alias || [],
    redirect: calendarPtrW19W49pMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/early-access/calendar.vue").then(m => m.default || m)
  },
  {
    name: drip_45campaignszw6UiiSkJrMeta?.name ?? "early-access-drip-campaigns",
    path: drip_45campaignszw6UiiSkJrMeta?.path ?? "/early-access/drip-campaigns",
    meta: drip_45campaignszw6UiiSkJrMeta || {},
    alias: drip_45campaignszw6UiiSkJrMeta?.alias || [],
    redirect: drip_45campaignszw6UiiSkJrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/early-access/drip-campaigns.vue").then(m => m.default || m)
  },
  {
    name: voice_45callsMvwviFPLgdMeta?.name ?? "early-access-voice-calls",
    path: voice_45callsMvwviFPLgdMeta?.path ?? "/early-access/voice-calls",
    meta: voice_45callsMvwviFPLgdMeta || {},
    alias: voice_45callsMvwviFPLgdMeta?.alias || [],
    redirect: voice_45callsMvwviFPLgdMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/early-access/voice-calls.vue").then(m => m.default || m)
  },
  {
    name: whatsapp_45messagingRgr3qqzNlHMeta?.name ?? "early-access-whatsapp-messaging",
    path: whatsapp_45messagingRgr3qqzNlHMeta?.path ?? "/early-access/whatsapp-messaging",
    meta: whatsapp_45messagingRgr3qqzNlHMeta || {},
    alias: whatsapp_45messagingRgr3qqzNlHMeta?.alias || [],
    redirect: whatsapp_45messagingRgr3qqzNlHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/early-access/whatsapp-messaging.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordSD7stX1k4CMeta?.name ?? "forgot-password",
    path: forgot_45passwordSD7stX1k4CMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordSD7stX1k4CMeta || {},
    alias: forgot_45passwordSD7stX1k4CMeta?.alias || [],
    redirect: forgot_45passwordSD7stX1k4CMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: inbox_45redirectZ6bxooKiJBMeta?.name ?? "inbox-redirect",
    path: inbox_45redirectZ6bxooKiJBMeta?.path ?? "/inbox-redirect",
    meta: inbox_45redirectZ6bxooKiJBMeta || {},
    alias: inbox_45redirectZ6bxooKiJBMeta?.alias || [],
    redirect: inbox_45redirectZ6bxooKiJBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/inbox-redirect.vue").then(m => m.default || m)
  },
  {
    name: indexHUp1zptPbXMeta?.name ?? "inbox-tenantId-phoneNumberId",
    path: indexHUp1zptPbXMeta?.path ?? "/inbox/:tenantId()/:phoneNumberId()",
    meta: indexHUp1zptPbXMeta || {},
    alias: indexHUp1zptPbXMeta?.alias || [],
    redirect: indexHUp1zptPbXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/inbox/[tenantId]/[phoneNumberId]/index.vue").then(m => m.default || m)
  },
  {
    name: alll2VhnZEjT8Meta?.name ?? "inbox-all",
    path: alll2VhnZEjT8Meta?.path ?? "/inbox/all",
    meta: alll2VhnZEjT8Meta || {},
    alias: alll2VhnZEjT8Meta?.alias || [],
    redirect: alll2VhnZEjT8Meta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/inbox/all.vue").then(m => m.default || m)
  },
  {
    name: indexE6Ktkgcf24Meta?.name ?? "index",
    path: indexE6Ktkgcf24Meta?.path ?? "/",
    meta: indexE6Ktkgcf24Meta || {},
    alias: indexE6Ktkgcf24Meta?.alias || [],
    redirect: indexE6Ktkgcf24Meta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhYZ9NKRMBlMeta?.name ?? "integrations-tenantId",
    path: indexhYZ9NKRMBlMeta?.path ?? "/integrations/:tenantId()",
    meta: indexhYZ9NKRMBlMeta || {},
    alias: indexhYZ9NKRMBlMeta?.alias || [],
    redirect: indexhYZ9NKRMBlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/integrations/[tenantId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91bookingId_93HXdxHmhwHuMeta?.name ?? "integrations-nylas-booking-bookingId",
    path: _91bookingId_93HXdxHmhwHuMeta?.path ?? "/integrations/nylas/booking/:bookingId()",
    meta: _91bookingId_93HXdxHmhwHuMeta || {},
    alias: _91bookingId_93HXdxHmhwHuMeta?.alias || [],
    redirect: _91bookingId_93HXdxHmhwHuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/integrations/nylas/booking/[bookingId].vue").then(m => m.default || m)
  },
  {
    name: nylas_45oauth_45handshakePQO7z0rVJrMeta?.name ?? "integrations-nylas-nylas-oauth-handshake",
    path: nylas_45oauth_45handshakePQO7z0rVJrMeta?.path ?? "/integrations/nylas/nylas-oauth-handshake",
    meta: nylas_45oauth_45handshakePQO7z0rVJrMeta || {},
    alias: nylas_45oauth_45handshakePQO7z0rVJrMeta?.alias || [],
    redirect: nylas_45oauth_45handshakePQO7z0rVJrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/integrations/nylas/nylas-oauth-handshake.vue").then(m => m.default || m)
  },
  {
    name: invalid_45permissionsO8dbeBOp4fMeta?.name ?? "invalid-permissions",
    path: invalid_45permissionsO8dbeBOp4fMeta?.path ?? "/invalid-permissions",
    meta: invalid_45permissionsO8dbeBOp4fMeta || {},
    alias: invalid_45permissionsO8dbeBOp4fMeta?.alias || [],
    redirect: invalid_45permissionsO8dbeBOp4fMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/invalid-permissions.vue").then(m => m.default || m)
  },
  {
    name: invalid_45url3llvEm9ZroMeta?.name ?? "invalid-url",
    path: invalid_45url3llvEm9ZroMeta?.path ?? "/invalid-url",
    meta: invalid_45url3llvEm9ZroMeta || {},
    alias: invalid_45url3llvEm9ZroMeta?.alias || [],
    redirect: invalid_45url3llvEm9ZroMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/invalid-url.vue").then(m => m.default || m)
  },
  {
    name: loginRJVap3EXlUMeta?.name ?? "login",
    path: loginRJVap3EXlUMeta?.path ?? "/login",
    meta: loginRJVap3EXlUMeta || {},
    alias: loginRJVap3EXlUMeta?.alias || [],
    redirect: loginRJVap3EXlUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutbcUwsM9BD9Meta?.name ?? "logout",
    path: logoutbcUwsM9BD9Meta?.path ?? "/logout",
    meta: logoutbcUwsM9BD9Meta || {},
    alias: logoutbcUwsM9BD9Meta?.alias || [],
    redirect: logoutbcUwsM9BD9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: _91tenantId_93knf6WSVX5qMeta?.name ?? "metrics-messages-campaigns-tenantId",
    path: _91tenantId_93knf6WSVX5qMeta?.path ?? "/metrics/messages-campaigns/:tenantId()",
    meta: _91tenantId_93knf6WSVX5qMeta || {},
    alias: _91tenantId_93knf6WSVX5qMeta?.alias || [],
    redirect: _91tenantId_93knf6WSVX5qMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/metrics/messages-campaigns/[tenantId].vue").then(m => m.default || m)
  },
  {
    name: updatecI4Oppca8XMeta?.name ?? "oneoff-campaigns-tenantId-phoneNumberId-campaignId-update",
    path: updatecI4Oppca8XMeta?.path ?? "/oneoff-campaigns/:tenantId()/:phoneNumberId()/:campaignId()/update",
    meta: updatecI4Oppca8XMeta || {},
    alias: updatecI4Oppca8XMeta?.alias || [],
    redirect: updatecI4Oppca8XMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/oneoff-campaigns/[tenantId]/[phoneNumberId]/[campaignId]/update.vue").then(m => m.default || m)
  },
  {
    name: createPdgkrtsVI5Meta?.name ?? "oneoff-campaigns-tenantId-phoneNumberId-create",
    path: createPdgkrtsVI5Meta?.path ?? "/oneoff-campaigns/:tenantId()/:phoneNumberId()/create",
    meta: createPdgkrtsVI5Meta || {},
    alias: createPdgkrtsVI5Meta?.alias || [],
    redirect: createPdgkrtsVI5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/oneoff-campaigns/[tenantId]/[phoneNumberId]/create.vue").then(m => m.default || m)
  },
  {
    name: indexOIYy7YJlJ3Meta?.name ?? "oneoff-campaigns-tenantId-phoneNumberId",
    path: indexOIYy7YJlJ3Meta?.path ?? "/oneoff-campaigns/:tenantId()/:phoneNumberId()",
    meta: indexOIYy7YJlJ3Meta || {},
    alias: indexOIYy7YJlJ3Meta?.alias || [],
    redirect: indexOIYy7YJlJ3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/oneoff-campaigns/[tenantId]/[phoneNumberId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDMNp27SZ6TMeta?.name ?? "password-set-loginId-token",
    path: indexDMNp27SZ6TMeta?.path ?? "/password-set/:loginId()/:token()",
    meta: indexDMNp27SZ6TMeta || {},
    alias: indexDMNp27SZ6TMeta?.alias || [],
    redirect: indexDMNp27SZ6TMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/password-set/[loginId]/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: redirectjoSUVrdyOWMeta?.name ?? "redirect",
    path: redirectjoSUVrdyOWMeta?.path ?? "/redirect",
    meta: redirectjoSUVrdyOWMeta || {},
    alias: redirectjoSUVrdyOWMeta?.alias || [],
    redirect: redirectjoSUVrdyOWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: indexiYbVvssV39Meta?.name ?? "seats-tenantId",
    path: indexiYbVvssV39Meta?.path ?? "/seats/:tenantId()",
    meta: indexiYbVvssV39Meta || {},
    alias: indexiYbVvssV39Meta?.alias || [],
    redirect: indexiYbVvssV39Meta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/seats/[tenantId]/index.vue").then(m => m.default || m)
  },
  {
    name: index2HFWYPcSDJMeta?.name ?? "shared-templates-tenantId",
    path: index2HFWYPcSDJMeta?.path ?? "/shared-templates/:tenantId()",
    meta: index2HFWYPcSDJMeta || {},
    alias: index2HFWYPcSDJMeta?.alias || [],
    redirect: index2HFWYPcSDJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/shared-templates/[tenantId]/index.vue").then(m => m.default || m)
  },
  {
    name: autocompletekW22AqDwCjMeta?.name ?? "test-atoms-autocomplete",
    path: autocompletekW22AqDwCjMeta?.path ?? "/test/atoms/autocomplete",
    meta: autocompletekW22AqDwCjMeta || {},
    alias: autocompletekW22AqDwCjMeta?.alias || [],
    redirect: autocompletekW22AqDwCjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/autocomplete.vue").then(m => m.default || m)
  },
  {
    name: breadcrumbsIIFUMHWU4vMeta?.name ?? "test-atoms-breadcrumbs",
    path: breadcrumbsIIFUMHWU4vMeta?.path ?? "/test/atoms/breadcrumbs",
    meta: breadcrumbsIIFUMHWU4vMeta || {},
    alias: breadcrumbsIIFUMHWU4vMeta?.alias || [],
    redirect: breadcrumbsIIFUMHWU4vMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/breadcrumbs.vue").then(m => m.default || m)
  },
  {
    name: buttons32Urn0dCvNMeta?.name ?? "test-atoms-buttons",
    path: buttons32Urn0dCvNMeta?.path ?? "/test/atoms/buttons",
    meta: buttons32Urn0dCvNMeta || {},
    alias: buttons32Urn0dCvNMeta?.alias || [],
    redirect: buttons32Urn0dCvNMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/buttons.vue").then(m => m.default || m)
  },
  {
    name: dividere8V2kxojUEMeta?.name ?? "test-atoms-divider",
    path: dividere8V2kxojUEMeta?.path ?? "/test/atoms/divider",
    meta: dividere8V2kxojUEMeta || {},
    alias: dividere8V2kxojUEMeta?.alias || [],
    redirect: dividere8V2kxojUEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/divider.vue").then(m => m.default || m)
  },
  {
    name: headerIG4jRpQrsYMeta?.name ?? "test-atoms-grids-header",
    path: headerIG4jRpQrsYMeta?.path ?? "/test/atoms/grids/header",
    meta: headerIG4jRpQrsYMeta || {},
    alias: headerIG4jRpQrsYMeta?.alias || [],
    redirect: headerIG4jRpQrsYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/grids/header.vue").then(m => m.default || m)
  },
  {
    name: no_45headerRXh73WoJVcMeta?.name ?? "test-atoms-grids-no-header",
    path: no_45headerRXh73WoJVcMeta?.path ?? "/test/atoms/grids/no-header",
    meta: no_45headerRXh73WoJVcMeta || {},
    alias: no_45headerRXh73WoJVcMeta?.alias || [],
    redirect: no_45headerRXh73WoJVcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/grids/no-header.vue").then(m => m.default || m)
  },
  {
    name: imagesRcoUs7ox8UMeta?.name ?? "test-atoms-images",
    path: imagesRcoUs7ox8UMeta?.path ?? "/test/atoms/images",
    meta: imagesRcoUs7ox8UMeta || {},
    alias: imagesRcoUs7ox8UMeta?.alias || [],
    redirect: imagesRcoUs7ox8UMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/images.vue").then(m => m.default || m)
  },
  {
    name: inputsjbcZPCF7LSMeta?.name ?? "test-atoms-inputs",
    path: inputsjbcZPCF7LSMeta?.path ?? "/test/atoms/inputs",
    meta: inputsjbcZPCF7LSMeta || {},
    alias: inputsjbcZPCF7LSMeta?.alias || [],
    redirect: inputsjbcZPCF7LSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/inputs.vue").then(m => m.default || m)
  },
  {
    name: listswbXvkpa2UDMeta?.name ?? "test-atoms-lists",
    path: listswbXvkpa2UDMeta?.path ?? "/test/atoms/lists",
    meta: listswbXvkpa2UDMeta || {},
    alias: listswbXvkpa2UDMeta?.alias || [],
    redirect: listswbXvkpa2UDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/lists.vue").then(m => m.default || m)
  },
  {
    name: onboarding09M66UPy4UMeta?.name ?? "test-atoms-onboarding",
    path: onboarding09M66UPy4UMeta?.path ?? "/test/atoms/onboarding",
    meta: onboarding09M66UPy4UMeta || {},
    alias: onboarding09M66UPy4UMeta?.alias || [],
    redirect: onboarding09M66UPy4UMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/onboarding.vue").then(m => m.default || m)
  },
  {
    name: tabsVlfEbsGPaIMeta?.name ?? "test-atoms-tabs",
    path: tabsVlfEbsGPaIMeta?.path ?? "/test/atoms/tabs",
    meta: tabsVlfEbsGPaIMeta || {},
    alias: tabsVlfEbsGPaIMeta?.alias || [],
    redirect: tabsVlfEbsGPaIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/tabs.vue").then(m => m.default || m)
  },
  {
    name: typographiesEQukClWBNhMeta?.name ?? "test-atoms-typographies",
    path: typographiesEQukClWBNhMeta?.path ?? "/test/atoms/typographies",
    meta: typographiesEQukClWBNhMeta || {},
    alias: typographiesEQukClWBNhMeta?.alias || [],
    redirect: typographiesEQukClWBNhMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/atoms/typographies.vue").then(m => m.default || m)
  },
  {
    name: avatarsQnLxFxlhuCMeta?.name ?? "test-business-avatars",
    path: avatarsQnLxFxlhuCMeta?.path ?? "/test/business/avatars",
    meta: avatarsQnLxFxlhuCMeta || {},
    alias: avatarsQnLxFxlhuCMeta?.alias || [],
    redirect: avatarsQnLxFxlhuCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/avatars.vue").then(m => m.default || m)
  },
  {
    name: chatVtD5yVcpsbMeta?.name ?? "test-business-chat",
    path: chatVtD5yVcpsbMeta?.path ?? "/test/business/chat",
    meta: chatVtD5yVcpsbMeta || {},
    alias: chatVtD5yVcpsbMeta?.alias || [],
    redirect: chatVtD5yVcpsbMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/chat.vue").then(m => m.default || m)
  },
  {
    name: cookiesQd1EQSdXmfMeta?.name ?? "test-business-cookies",
    path: cookiesQd1EQSdXmfMeta?.path ?? "/test/business/cookies",
    meta: cookiesQd1EQSdXmfMeta || {},
    alias: cookiesQd1EQSdXmfMeta?.alias || [],
    redirect: cookiesQd1EQSdXmfMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/cookies.vue").then(m => m.default || m)
  },
  {
    name: flagsfvCPwIiVplMeta?.name ?? "test-business-flags",
    path: flagsfvCPwIiVplMeta?.path ?? "/test/business/flags",
    meta: flagsfvCPwIiVplMeta || {},
    alias: flagsfvCPwIiVplMeta?.alias || [],
    redirect: flagsfvCPwIiVplMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/flags.vue").then(m => m.default || m)
  },
  {
    name: kpisy9s9BzTJiwMeta?.name ?? "test-business-kpis",
    path: kpisy9s9BzTJiwMeta?.path ?? "/test/business/kpis",
    meta: kpisy9s9BzTJiwMeta || {},
    alias: kpisy9s9BzTJiwMeta?.alias || [],
    redirect: kpisy9s9BzTJiwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/kpis.vue").then(m => m.default || m)
  },
  {
    name: listMUI1TlEcFuMeta?.name ?? "test-business-list",
    path: listMUI1TlEcFuMeta?.path ?? "/test/business/list",
    meta: listMUI1TlEcFuMeta || {},
    alias: listMUI1TlEcFuMeta?.alias || [],
    redirect: listMUI1TlEcFuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/list.vue").then(m => m.default || m)
  },
  {
    name: logosntQ1Tpkm3BMeta?.name ?? "test-business-logos",
    path: logosntQ1Tpkm3BMeta?.path ?? "/test/business/logos",
    meta: logosntQ1Tpkm3BMeta || {},
    alias: logosntQ1Tpkm3BMeta?.alias || [],
    redirect: logosntQ1Tpkm3BMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/logos.vue").then(m => m.default || m)
  },
  {
    name: menusVXEqdwVGJmMeta?.name ?? "test-business-menus",
    path: menusVXEqdwVGJmMeta?.path ?? "/test/business/menus",
    meta: menusVXEqdwVGJmMeta || {},
    alias: menusVXEqdwVGJmMeta?.alias || [],
    redirect: menusVXEqdwVGJmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/menus.vue").then(m => m.default || m)
  },
  {
    name: seatvkTQa5TNjTMeta?.name ?? "test-business-seat",
    path: seatvkTQa5TNjTMeta?.path ?? "/test/business/seat",
    meta: seatvkTQa5TNjTMeta || {},
    alias: seatvkTQa5TNjTMeta?.alias || [],
    redirect: seatvkTQa5TNjTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/seat.vue").then(m => m.default || m)
  },
  {
    name: tablesOeMhmKNduuMeta?.name ?? "test-business-tables",
    path: tablesOeMhmKNduuMeta?.path ?? "/test/business/tables",
    meta: tablesOeMhmKNduuMeta || {},
    alias: tablesOeMhmKNduuMeta?.alias || [],
    redirect: tablesOeMhmKNduuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/tables.vue").then(m => m.default || m)
  },
  {
    name: templatesWm29mJJ0fDMeta?.name ?? "test-business-templates",
    path: templatesWm29mJJ0fDMeta?.path ?? "/test/business/templates",
    meta: templatesWm29mJJ0fDMeta || {},
    alias: templatesWm29mJJ0fDMeta?.alias || [],
    redirect: templatesWm29mJJ0fDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/templates.vue").then(m => m.default || m)
  },
  {
    name: textsDXDOqm151XMeta?.name ?? "test-business-texts",
    path: textsDXDOqm151XMeta?.path ?? "/test/business/texts",
    meta: textsDXDOqm151XMeta || {},
    alias: textsDXDOqm151XMeta?.alias || [],
    redirect: textsDXDOqm151XMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/texts.vue").then(m => m.default || m)
  },
  {
    name: urlShortener82oGVhq5eSMeta?.name ?? "test-business-urlShortener",
    path: urlShortener82oGVhq5eSMeta?.path ?? "/test/business/urlShortener",
    meta: urlShortener82oGVhq5eSMeta || {},
    alias: urlShortener82oGVhq5eSMeta?.alias || [],
    redirect: urlShortener82oGVhq5eSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/urlShortener.vue").then(m => m.default || m)
  },
  {
    name: variableswxuZO2szGPMeta?.name ?? "test-business-variables",
    path: variableswxuZO2szGPMeta?.path ?? "/test/business/variables",
    meta: variableswxuZO2szGPMeta || {},
    alias: variableswxuZO2szGPMeta?.alias || [],
    redirect: variableswxuZO2szGPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/business/variables.vue").then(m => m.default || m)
  },
  {
    name: chips_45and_45tagsnBLPNONz29Meta?.name ?? "test-chips-and-tags",
    path: chips_45and_45tagsnBLPNONz29Meta?.path ?? "/test/chips-and-tags",
    meta: chips_45and_45tagsnBLPNONz29Meta || {},
    alias: chips_45and_45tagsnBLPNONz29Meta?.alias || [],
    redirect: chips_45and_45tagsnBLPNONz29Meta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/chips-and-tags.vue").then(m => m.default || m)
  },
  {
    name: chrome_45extensionkAyvGXJrvCMeta?.name ?? "test-chrome-extension",
    path: chrome_45extensionkAyvGXJrvCMeta?.path ?? "/test/chrome-extension",
    meta: chrome_45extensionkAyvGXJrvCMeta || {},
    alias: chrome_45extensionkAyvGXJrvCMeta?.alias || [],
    redirect: chrome_45extensionkAyvGXJrvCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/chrome-extension.vue").then(m => m.default || m)
  },
  {
    name: errorsvL3hKOlaxRMeta?.name ?? "test-errors",
    path: errorsvL3hKOlaxRMeta?.path ?? "/test/errors",
    meta: errorsvL3hKOlaxRMeta || {},
    alias: errorsvL3hKOlaxRMeta?.alias || [],
    redirect: errorsvL3hKOlaxRMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/errors.vue").then(m => m.default || m)
  },
  {
    name: indexzjTovjeqipMeta?.name ?? "test",
    path: indexzjTovjeqipMeta?.path ?? "/test",
    meta: indexzjTovjeqipMeta || {},
    alias: indexzjTovjeqipMeta?.alias || [],
    redirect: indexzjTovjeqipMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/index.vue").then(m => m.default || m)
  },
  {
    name: local_45storage97z8gofIsnMeta?.name ?? "test-local-storage",
    path: local_45storage97z8gofIsnMeta?.path ?? "/test/local-storage",
    meta: local_45storage97z8gofIsnMeta || {},
    alias: local_45storage97z8gofIsnMeta?.alias || [],
    redirect: local_45storage97z8gofIsnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/local-storage.vue").then(m => m.default || m)
  },
  {
    name: buttonsQWNFZnomUSMeta?.name ?? "test-molecules-buttons",
    path: buttonsQWNFZnomUSMeta?.path ?? "/test/molecules/buttons",
    meta: buttonsQWNFZnomUSMeta || {},
    alias: buttonsQWNFZnomUSMeta?.alias || [],
    redirect: buttonsQWNFZnomUSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/buttons.vue").then(m => m.default || m)
  },
  {
    name: footers761Ab0hTLTMeta?.name ?? "test-molecules-footers",
    path: footers761Ab0hTLTMeta?.path ?? "/test/molecules/footers",
    meta: footers761Ab0hTLTMeta || {},
    alias: footers761Ab0hTLTMeta?.alias || [],
    redirect: footers761Ab0hTLTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/footers.vue").then(m => m.default || m)
  },
  {
    name: loadingYLTaRXrpdeMeta?.name ?? "test-molecules-loading",
    path: loadingYLTaRXrpdeMeta?.path ?? "/test/molecules/loading",
    meta: loadingYLTaRXrpdeMeta || {},
    alias: loadingYLTaRXrpdeMeta?.alias || [],
    redirect: loadingYLTaRXrpdeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/loading.vue").then(m => m.default || m)
  },
  {
    name: menusRoTu0kGtPaMeta?.name ?? "test-molecules-menus",
    path: menusRoTu0kGtPaMeta?.path ?? "/test/molecules/menus",
    meta: menusRoTu0kGtPaMeta || {},
    alias: menusRoTu0kGtPaMeta?.alias || [],
    redirect: menusRoTu0kGtPaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/menus.vue").then(m => m.default || m)
  },
  {
    name: paginationiaWMX5kc75Meta?.name ?? "test-molecules-pagination",
    path: paginationiaWMX5kc75Meta?.path ?? "/test/molecules/pagination",
    meta: paginationiaWMX5kc75Meta || {},
    alias: paginationiaWMX5kc75Meta?.alias || [],
    redirect: paginationiaWMX5kc75Meta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/pagination.vue").then(m => m.default || m)
  },
  {
    name: stepperZiZA5iv1xxMeta?.name ?? "test-molecules-stepper",
    path: stepperZiZA5iv1xxMeta?.path ?? "/test/molecules/stepper",
    meta: stepperZiZA5iv1xxMeta || {},
    alias: stepperZiZA5iv1xxMeta?.alias || [],
    redirect: stepperZiZA5iv1xxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/stepper.vue").then(m => m.default || m)
  },
  {
    name: text7LpGGbacXzMeta?.name ?? "test-molecules-text",
    path: text7LpGGbacXzMeta?.path ?? "/test/molecules/text",
    meta: text7LpGGbacXzMeta || {},
    alias: text7LpGGbacXzMeta?.alias || [],
    redirect: text7LpGGbacXzMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/text.vue").then(m => m.default || m)
  },
  {
    name: transitionsWcsUbUJATmMeta?.name ?? "test-molecules-transitions",
    path: transitionsWcsUbUJATmMeta?.path ?? "/test/molecules/transitions",
    meta: transitionsWcsUbUJATmMeta || {},
    alias: transitionsWcsUbUJATmMeta?.alias || [],
    redirect: transitionsWcsUbUJATmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/molecules/transitions.vue").then(m => m.default || m)
  },
  {
    name: piniaecnnyM5gpmMeta?.name ?? "test-pinia",
    path: piniaecnnyM5gpmMeta?.path ?? "/test/pinia",
    meta: piniaecnnyM5gpmMeta || {},
    alias: piniaecnnyM5gpmMeta?.alias || [],
    redirect: piniaecnnyM5gpmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/test/pinia.vue").then(m => m.default || m)
  },
  {
    name: user_45preferencesmEdISlIsJsMeta?.name ?? "user-preferences",
    path: user_45preferencesmEdISlIsJsMeta?.path ?? "/user-preferences",
    meta: user_45preferencesmEdISlIsJsMeta || {},
    alias: user_45preferencesmEdISlIsJsMeta?.alias || [],
    redirect: user_45preferencesmEdISlIsJsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/user-preferences.vue").then(m => m.default || m)
  },
  {
    name: indexFbHVU33VTbMeta?.name ?? "whatsapp-templates-tenantId-phoneNumberId",
    path: indexFbHVU33VTbMeta?.path ?? "/whatsapp-templates/:tenantId()/:phoneNumberId()",
    meta: indexFbHVU33VTbMeta || {},
    alias: indexFbHVU33VTbMeta?.alias || [],
    redirect: indexFbHVU33VTbMeta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/whatsapp-templates/[tenantId]/[phoneNumberId]/index.vue").then(m => m.default || m)
  },
  {
    name: onboardavrQCtjWc5Meta?.name ?? "whatsapp-onboard",
    path: onboardavrQCtjWc5Meta?.path ?? "/whatsapp/onboard",
    meta: onboardavrQCtjWc5Meta || {},
    alias: onboardavrQCtjWc5Meta?.alias || [],
    redirect: onboardavrQCtjWc5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/emissary-webgui/emissary-webgui/pages/whatsapp/onboard.vue").then(m => m.default || m)
  }
]