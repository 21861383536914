import validate from "/home/runner/work/emissary-webgui/emissary-webgui/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45chrome_45extension_45global from "/home/runner/work/emissary-webgui/emissary-webgui/middleware/01.chromeExtension.global.ts";
import _02_45hash_45ids_45global from "/home/runner/work/emissary-webgui/emissary-webgui/middleware/02.hashIds.global.ts";
import _03_45private_45global from "/home/runner/work/emissary-webgui/emissary-webgui/middleware/03.private.global.ts";
export const globalMiddleware = [
  validate,
  _01_45chrome_45extension_45global,
  _02_45hash_45ids_45global,
  _03_45private_45global
]
export const namedMiddleware = {}