import { useChromeExtensionStore } from '@/stores/chromeExtension'
import { useUserStore } from '@/stores/user'
export default defineNuxtRouteMiddleware((to) => {
  const { editable: token } = useUserStore()
  const { editable: editableChromExtensionPrefs, login, sendCurrentPage } = useChromeExtensionStore()
  const editableExtension = editableChromExtensionPrefs.extension()
  const extension = {
    eid: to.query?.eid || null,
    page: to.query?.page || null,
    detail: to.query?.detail || null
  }
  if (extension.eid) {
    editableExtension.value = extension
    if (token) {
      login()
      if (to.path === '/') {
        return navigateTo('/chrome-extension/')
      }
      return navigateTo(to.path)
    }
  }
  sendCurrentPage(to.fullPath)
})
