import { camelCase } from 'lodash'
import { defineNuxtPlugin, NuxtApp } from 'nuxt/app'
import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import InboxOptionsObserver from '@/services/injectables/utils/InboxOptionsObserver'
import { Service } from '@/services/helpers/ServiceHelper'
window.global = window

// DYNAMIC IMPORT OF SERVICES FROM API INTO SERVICES HELPER
const injectableServiceFolderContexts = [
  {
    type: 'api',
    requireContext: import.meta.glob('@/services/injectables/api/*.@(js|ts)')
  },
  {
    type: 'gql',
    requireContext: import.meta.glob('@/services/injectables/gql/*.@(js|ts)')
  },
  {
    type: 'capacitor',
    requireContext: import.meta.glob('@/services/injectables/capacitor/*.@(js|ts)')
  }
]
type InjectableServiceType = {
  Clazz: any,
  type: string,
  serviceName: string,
}
const injectableServices: Promise<InjectableServiceType>[] = injectableServiceFolderContexts.map(({ requireContext, type }) => {
  const maxRetries = 3
  const getClassFromFullFileName = async (fullFileName: string, retries = 0): Promise<Record<string, any>> => {
    try {
      return <Record<string, any>>(await requireContext[fullFileName]())
    } catch (err) {
      if (retries < maxRetries) {
        return await getClassFromFullFileName(fullFileName, retries + 1)
      } else {
        throw new Error(`Failed to import ${fullFileName} after ${maxRetries} retries. Error: ${err}`)
      }
    }
  }
  return Object.keys(requireContext).map(async (fullFileName) => {
    const clazz = await getClassFromFullFileName(fullFileName)
    const filename = fullFileName.split('/').pop()!.split('.')[0]
    const serviceName = camelCase(filename)
    return { Clazz: clazz.default, type, serviceName }
  })
}).flat()

export default defineNuxtPlugin(async (nuxtApp: NuxtApp) => {
  try {
    const inboxOptionsObserver = new InboxOptionsObserver()
    nuxtApp.provide('inboxOptionsObserver', inboxOptionsObserver)
    nuxtApp.provide('flushQueue', (delay = 0) => new Promise(resolve => setTimeout(resolve, delay)))
    // INJECTING SERVICES INTO SERVICES.TS
    await Promise.all(injectableServices.map(async (servicePromise) => {
      try {
        const injectableService = await servicePromise
        const instance = new injectableService.Clazz({ $axios: nuxtApp.$axios, router: nuxtApp.$router, $apollo: nuxtApp.$apollo.defaultClient })
        Service[injectableService.type][injectableService.serviceName] = instance
      } catch (error: any) {
        throw new Error('Error initializing service: ', error)
      }
    }))
    Service.$axiosInstance = <NuxtAxiosInstance>nuxtApp.$axios
  } catch (error: any) {
    throw new Error('Error in plugin initialization: ', error)
  }
})
