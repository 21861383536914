<template>
  <v-btn
    v-bind="$attrs"
    class="no-text-transform em-terciary-button"
    :class="{ 'em-terciary-button--shadow': showShadow, 'px-3': display.xs && mobileIcon }"
    :color="color"
    variant="text"
    :size="size"
  >
    <template v-for="(_, slotName) in $slots" #[slotName]>
      <slot :name="slotName" />
    </template>
    <template v-if="display[mobileBreakpoint] && mobileIcon" #default>
      <EmIcon :icon="mobileIcon" />
    </template>
  </v-btn>
</template>

<script setup>
import { useDisplay } from 'vuetify'
const display = ref(useDisplay())
defineProps({
  color: { type: String, default: 'primary' },
  prependIcon: { type: String, default: '' },
  appendIcon: { type: String, default: '' },
  size: { type: String, default: 'default' },
  showShadow: { type: Boolean, default: false },
  mobileIcon: { type: String, default: null },
  mobileBreakpoint: { type: String, default: 'smAndDown' }
})
</script>
<style>
.theme--light .em-terciary-button--shadow::before{
  opacity: 0.04 !important;
}
</style>
