export default class InboxOptionsObserver {
  constructor () {
    this.observers = []
  }

  attach (observer) {
    this.observers.push(observer)
  }

  closeAllMenus () {
    this.observers.forEach((observer) => {
      observer.exposed.closeMenu()
    })
  }
}
