<template>
  <v-btn
    v-bind="$attrs"
    class="text-white no-text-transform primary-color"
    elevation="0"
    :class="{ 'px-3': display.xs && mobileIcon }"
  >
    <template v-for="(_, slotName) in $slots" #[slotName]>
      <slot :name="slotName" />
    </template>
    <template v-if="display[mobileBreakpoint] && mobileIcon" #default>
      <EmIcon :icon="mobileIcon" color="white" />
    </template>
  </v-btn>
</template>

<script setup>
import { useDisplay } from 'vuetify'
const display = ref(useDisplay())
defineProps({
  mobileIcon: { type: String, default: null },
  mobileBreakpoint: { type: String, default: 'smAndDown' }
})
</script>
<style scoped>
.primary-color {
  background-color: rgb(var(--v-theme-primary)) !important;
}
.primary-color:hover {
  background-color: rgb(var(--v-theme-primary-lighten-4)) !important;
}
</style>
