<template>
  <EmDialog
    v-model="open"
    v-bind="{...$attrs, ...dialogProps}"
    width="500"
    @cancel="onCancel"
    @confirm="onConfirm"
  >
    <template #default>
      <EmAlert v-if="alertType" :type="alertType">
        <EmBody2 semi-bold>
          {{ alertBody }}
        </EmBody2>
      </EmAlert>
      <EmBody2 v-else semi-bold>
        {{ alertBody }}
      </EmBody2>
    </template>
  </EmDialog>
</template>
<script setup>
const open = ref(false)
const alertBody = ref('')
const alertType = ref('info')
const action = ref(() => {})
const cancelAction = ref(() => {})
const dialogProps = ref({})
const emits = defineEmits(['destroy'])
watch(open, (val) => {
  if (!val) {
    emits('destroy')
  }
})
const show = ({ text, type, action: newAction, cancelAction: newCancelAction, dialogProps: newDialogProps }) => {
  open.value = true
  alertBody.value = text
  alertType.value = type
  action.value = newAction
  cancelAction.value = newCancelAction
  dialogProps.value = newDialogProps
}
const hide = () => {
  open.value = false
}
const onConfirm = () => {
  if (action.value) {
    action.value()
  }
  hide()
}
const onCancel = () => {
  if (cancelAction.value) {
    cancelAction.value()
  }
  hide()
}
defineExpose({
  show
})
</script>
