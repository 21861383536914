<template>
  <div class="d-flex flex-column justify-center">
    <div class="d-flex justify-center">
      <v-card elevation="0" :color="colorClass || 'white'" :class="display.xs ? 'pa-3' : 'pa-10'">
        <EmIcon
          :color="iconColor"
          :size="iconSize"
          :icon="icon"
        />
      </v-card>
    </div>
    <div v-if="title" class="text-center mb-3" :class="`${hasTitleOnly || display.xs ? 'mt-3': 'mt-10'}`">
      <EmBody1 v-if="display.xs" semi-bold>
        {{ title }}
      </EmBody1>
      <EmH3 v-else>
        {{ title }}
      </EmH3>
    </div>
    <slot name="subtitle">
      <div v-if="subtitle" class="text-center">
        <EmBody2 v-if="display.xs">
          {{ subtitle }}
        </EmBody2>
        <EmBody1 v-else>
          {{ subtitle }}
        </EmBody1>
      </div>
    </slot>
    <slot name="bodyText" />
    <div v-if="btnText || secondaryBtnText" class="d-flex justify-center mt-6">
      <EmTerciaryButton
        v-if="secondaryBtnText"
        class="mx-2"
        @click="() => {$emit('secondaryClick', $event)}"
      >
        {{ secondaryBtnText }}
      </EmTerciaryButton>
      <EmButton
        v-if="btnText"
        class="mx-2"
        :loading="loading"
        :data-cy="dataCy"
        @click="$emit('click', $event)"
      >
        {{ btnText }}
      </EmButton>
    </div>
    <div v-if="$slots.append" class="d-flex justify-center mt-7">
      <slot name="append" />
    </div>
  </div>
</template>

<script setup>
import { useDisplay } from 'vuetify'
defineEmits(['secondaryClick', 'click'])
const display = ref(useDisplay())
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: ''
  },
  btnText: {
    type: String,
    default: ''
  },
  colorClass: {
    type: String,
    default: ''
  },
  secondaryBtnText: {
    type: String,
    default: ''
  },
  loading: {
    type: Boolean,
    default: false
  },
  iconSize: {
    type: String,
    default: 'large'
  },
  iconColor: {
    type: String,
    default: 'primary'
  }
})
const hasTitleOnly = computed(() => {
  return !props.subtitle && !props.btnText && !props.secondaryBtnText
})
const $attrs = useAttrs()
const dataCy = computed(() => {
  return $attrs['data-cy-form-field']
})
</script>
