import { debounce } from 'lodash'
import { defineStore } from 'pinia'
import { Capacitor } from '@capacitor/core'
import { Service } from '@/services/helpers/ServiceHelper'
import useSnackbar from '@/composables/useSnackbar'
import useDialog from '@/composables/useDialog'
import { DevicePlatform } from '~/services/injectables/api/PushNotificationsService'

const snackbar = useSnackbar()
const dialog = useDialog()
const instance = useInstance()

type Notification = {
    alreadyAskedForPushNotificationsPermissions: any,
    hasLocalNotificationsPermission: any,
    hasPushNotificationsPermission: any,
    registerTokenForLater: any,
}

const getDefaultState = () => ({
  alreadyAskedForPushNotificationsPermissions: localStorage.getItem('alreadyAskedForPushNotificationsPermissions'),
  hasLocalNotificationsPermission: localStorage.getItem('hasLocalNotificationsPermission'),
  hasPushNotificationsPermission: localStorage.getItem('hasPushNotificationsPermission'),
  registerTokenForLater: localStorage.getItem('registerTokenForLater')
})

export const usePushNotificationStore = defineStore('pushNotification', () => {
  const notification = ref<Notification>(getDefaultState())
  const token = ref<string|null>(localStorage.getItem('deviceToken'))
  if (Capacitor.isNativePlatform()) {
    Service.capacitor.storageCapacitor?.get({ key: 'pushNotificationToken' }).then((result: string) => {
      token.value = result
    })
  }
  const tokenRegistered = computed<string | null>({
    get: () => token.value,
    set: (value) => {
      token.value = value
      if (Capacitor.isNativePlatform()) {
        Service.capacitor.storageCapacitor?.set({ key: 'pushNotificationToken', value: token })
      }
      localStorage.setItem('deviceToken', value || '')
    }
  })
  const notificationEnabledValue = ref<boolean | null>(Boolean(localStorage.getItem('notificationEnabled')) || null)
  const notificationEnabled = computed<boolean | null>({
    get: () => notificationEnabledValue.value,
    set: (value) => {
      if (!value) {
        localStorage.removeItem('notificationEnabled')
        notificationEnabledValue.value = null
      } else {
        localStorage.setItem('notificationEnabled', value.toString())
        notificationEnabledValue.value = value
      }
    }
  })

  const alreadyAskedForPushNotificationsPermissions = computed({
    get: () => notification.value.alreadyAskedForPushNotificationsPermissions,
    set: (value) => {
      localStorage.setItem('alreadyAskedForPushNotificationsPermissions', value)
      notification.value.alreadyAskedForPushNotificationsPermissions = value
    }
  })

  const hasLocalNotificationsPermission = computed({
    get: () => notification.value.hasLocalNotificationsPermission,
    set: (value) => {
      localStorage.setItem('hasLocalNotificationsPermission', value)
      notification.value.hasLocalNotificationsPermission = value
    }
  })

  const hasPushNotificationsPermission = computed({
    get: () => notification.value.hasPushNotificationsPermission,
    set: (value) => {
      localStorage.setItem('hasPushNotificationsPermission', value)
      notification.value.hasPushNotificationsPermission = value
    }
  })

  const registerTokenForLater = computed({
    get: () => notification.value.registerTokenForLater,
    set: (value) => {
      if (value) {
        localStorage.setItem('registerTokenForLater', value)
      } else {
        localStorage.removeItem('registerTokenForLater')
      }
      notification.value.registerTokenForLater = value
    }
  })

  const getLocalNotificationPermissions = async () => {
    const permission = await Service.capacitor.localNotificationCapacitor!.checkOrRequestPermission()
    const hasPermission = permission.isGranted
    if (hasPermission) {
      hasPushNotificationsPermission.value = true
    }
    return hasPermission
  }

  const getPushNotificationPermissions = async () => {
    const permission = await Service.capacitor.pushNotificationCapacitor!.checkOrRequestPermission()
    const hasPermission = permission.isGranted
    if (hasPermission) {
      hasLocalNotificationsPermission.value = true
    }
    return hasPermission
  }

  const requestNotificationPermission = async () => {
    // Ask for permission
    const hasNotificationPermissions = (await getLocalNotificationPermissions() && (await getPushNotificationPermissions()))
    return hasNotificationPermissions
  }

  const registerPushTokenOnEmissary = async (pushNotificationsRegisterToken:any) => {
    const { readable: { platform } } = useDeviceStore()
    if (platform().value && pushNotificationsRegisterToken) {
      // eslint-disable-next-line no-console
      console.log('[Sending token to Emissary]')
      try {
        await Service.api.pushNotificationsService!.registerDevice(pushNotificationsRegisterToken, platform().value as DevicePlatform)
        snackbar.notify('Notifications are enabled, you will receive one every time you receive a new message.')
        registerTokenForLater.value = null
      } catch (error:any) {
        // eslint-disable-next-line no-console
        console.log('[Token regsitration error]')
        // eslint-disable-next-line no-console
        console.error(error)
        // eslint-disable-next-line no-console
        console.log(error.response?.data)
      }
    }
  }

  const showNotification = async (notification:any) => {
    const appState = await instance?.$appCapacitor.getState()
    const isAppOpen = appState?.isActive
    const hasToShowNotification = hasLocalNotificationsPermission.value && notification && isAppOpen
    if (hasToShowNotification) {
      /* We decide to comment this block of code because Capacitor already handle notifications automatically.
      https://app.shortcut.com/emissary-ai/story/5538/mobile-double-notification
      const contactNameOrPhone = notification?.title || notification?.from_number
      const messageData = {
         thread: notification.thread_id,
         channelSid: notification.sms_sid,
         tenantId: notification.tenantId,
         phoneNumberId: notification.phoneNumberId
       }
       Service.capacitor.localNotificationCapacitor.toastMessageNotification(notification.body, contactNameOrPhone, messageData)
       */
    }
  }

  const registerDeviceForNotifications = async () => {
    const registerTokenOnEmissary = debounce(async (token) => {
      try {
        await registerPushTokenOnEmissary(token)
        tokenRegistered.value = token
        console.log('token: ', token)
      } catch (error) {
        console.log('token: ', token, error)
        registerTokenForLater.value = token
      }
    }, 100)
    const onNotificationReceived = debounce((notification) => {
      showNotification(notification)
    }, 100)
    try {
      // Setup listeners
      Service.capacitor.localNotificationCapacitor!.registerNotifications()
      await Service.capacitor.pushNotificationCapacitor!.addListeners(
        registerTokenOnEmissary,
        // eslint-disable-next-line no-console
        () => console.log('onActionPerformed'),
        onNotificationReceived
      )
      // eslint-disable-next-line no-console
      console.log('[Registering device for notifications]')
      await Service.capacitor.pushNotificationCapacitor!.register()
    } catch {
      snackbar.warning('There was an error while setting up your notifications.')
    }
  }

  // SETUP PERMISSIONS AND NOTIFICATIONS
  const promptToAskForNotificationsPermission = async () => {
    const { isMobileDevice } = useDeviceStore()
    if (!(await isMobileDevice())) {
      return
    }
    const hasToShowPermissionDialog = !alreadyAskedForPushNotificationsPermissions.value
    if (hasToShowPermissionDialog) {
      showMobilePermissionDialog()
    }
  }
  const showMobilePermissionDialog = () => {
    dialog.info({
      text: 'We use notifications to allow you to receive messages from your Emissary contacts. Please grant us permission to send you notifications.',
      title: 'Notifications',
      action: async () => {
        const hasPermission = await requestNotificationPermission()
        alreadyAskedForPushNotificationsPermissions.value = true
        if (hasPermission) {
          notificationEnabled.value = true
          registerDeviceForNotifications()
        } else {
          snackbar.warning('Please enable notifications to receive the latest conversations updates.')
        }
      },
      dialogProps: {
        confirmText: 'Understood',
        showCancel: false,
        confirmButtonProps: {
          block: true
        }
      }
    })
  }
  const unregisterDeviceForNotifications = () => {
    hasLocalNotificationsPermission.value = false
    hasPushNotificationsPermission.value = false
  }

  const checkAndUpdatePermission = async () => {
    const { disableNotificationsReminder, showNotificationsReminder, setNotificationsReminder } = useRemindersStore()
    const hasLocalNotificationPermission = (await Service.capacitor.localNotificationCapacitor!.checkPermission())?.isGranted
    const hasPushNotificationPermission = (await Service.capacitor.pushNotificationCapacitor!.checkPermission())?.isGranted
    const hasPermission = hasLocalNotificationPermission && hasPushNotificationPermission
    const usedToHavePermission = hasLocalNotificationsPermission.value && hasPushNotificationsPermission.value
    if (hasPermission) {
      // If the user grants permission or if the permission is granted before the reminder date arrives
      disableNotificationsReminder()
    } else {
      showNotificationsReminder()
      setNotificationsReminder()
    }
    if (hasPermission && !usedToHavePermission) {
      await registerDeviceForNotifications()
    } else if (hasPermission && registerTokenForLater.value) {
      registerPushTokenOnEmissary(registerTokenForLater.value)
    }
    if (!hasPermission && usedToHavePermission) {
      await unregisterDeviceForNotifications()
    }
    hasLocalNotificationsPermission.value = hasLocalNotificationPermission
    hasPushNotificationsPermission.value = hasPushNotificationPermission
  }

  const clearAllNotifications = () => {
    Service.capacitor.pushNotificationCapacitor!.removeAllDeliveredNotifications()
  }

  const clearNotificationPermission = () => {
    dialog.info({
      text: 'To disabled notifications in this device you will need to reset them from your device configuration',
      title: 'Notifications',
      // action: () => { tokenRegistered.value = null },
      dialogProps: {
        confirmText: 'Understood',
        showCancel: false,
        confirmButtonProps: {
          block: true
        }
      }
    })
  }

  return {
    readable: {
      notificationEnabled,
      tokenRegistered
    },
    promptToAskForNotificationsPermission,
    showMobilePermissionDialog,
    checkAndUpdatePermission,
    clearAllNotifications,
    clearNotificationPermission
  }
})
