import { get } from 'lodash'
import { defineStore } from 'pinia'
import * as Sentry from '@sentry/browser'
import { useTimeZoneStore } from '@/stores/timeZone'
import { useThreadStore } from '@/stores/thread'
import { Service } from '@/services/helpers/ServiceHelper'
import useDialog from '@/composables/useDialog'
import { MS_PER } from '@/services/types/Time'
import { AvailableLanguage, Maybe } from '~/types/generated'

const dialog = useDialog()
const OPT_OUT_MESSAGE = 'Text STOP to unsubscribe.'
type User = {
  currentTenant: string | null,
  userInfo: any,
  loadingUserInformation: boolean,
  loadingMenus: boolean,
  tenants: Array<any>,
  phoneMenus: Array<any> | null,
  token: string | null,
  availableLanguages: Array<any>,
  locale: string
}

type Message = {
  id: number,
  title: string,
  message: string,
  severity: string
}

type Refresher = {
  interval: any,
  enable: () => void,
  disable: () => void
}

const getDefaultState = () => ({
  currentTenant: localStorage.getItem('currentTenant'),
  userInfo: null,
  loadingUserInformation: false,
  loadingMenus: false,
  tenants: [],
  phoneMenus: null,
  token: localStorage.getItem('auth-token'),
  availableLanguages: [],
  locale: 'en'
})
const snackbar = useSnackbar()
export const useUserStore = defineStore('user', () => {
  const { onlyLettersAndNumbers } = useFilters()
  const timeZoneStore = useTimeZoneStore()
  const threadStore = useThreadStore()
  const { CAMPAINGS_PERMISSIONS } = useActivePhoneNumber()

  const user = ref<User>(getDefaultState())
  const userServicesPromise = ref<Promise<any> | null>(null)
  const messages = ref<Array<Message>>([])

  const currentTenantId: any = computed({
    get: () => user.value.currentTenant,
    set: (value) => {
      localStorage.setItem('currentTenant', <string>value)
      user.value.currentTenant = value
    }
  })

  const tenantFeatures = computed(() => {
    const currentTenant = user.value?.tenants?.find(tenant => tenant.id === currentTenantId.value)
    return currentTenant?.feature_flags || []
  })

  const hasOOOPermission = computed(() => {
    return tenantFeatures.value.includes('out_of_office')
  })

  const isAdminOrOwner = computed(() => {
    const tenantInformation = userInfo.value?.memberships.find((tenant: { tenant_id: any }) => tenant.tenant_id === currentTenantId.value)
    return ['admin', 'owner'].includes(tenantInformation?.type)
  })

  const userInfo = computed(() => user.value.userInfo)

  const loadingMenus = computed(() => user.value.loadingMenus)

  const loadingUserInformation = computed(() => user.value.loadingUserInformation)

  const tenants = computed(() => user.value.tenants)

  const availableLanguages = computed(() => user.value.availableLanguages)

  const locale = computed(() => user.value.locale)

  const phoneMenus = computed({
    get: () => {
      return user.value.phoneMenus
    },
    set: (value) => {
      const sortByInboxName = (inbox: any, otherInbox: any) => {
        if (!inbox.inbox_name && !otherInbox.inbox_name) {
          return onlyLettersAndNumbers(inbox.phone_number) < onlyLettersAndNumbers(otherInbox.phone_number) ? -1 : 1
        }
        if (!inbox.inbox_name) { return 1 }
        if (!otherInbox.inbox_name) { return -1 }
        return inbox.inbox_name.localeCompare(otherInbox.inbox_name)
      }
      user.value.phoneMenus = value!.map((phone: any) => {
        const menus = []
        if (phone.chat_enabled) {
          menus.push({
            to: `/inbox/${phone.tenantId}/${phone.id}`,
            title: 'Inbox',
            icon: 'mdi-inbox',
            hasNotification: false,
            description: 'Start a conversation by accesing the Inbox interface!'
          })
        }
        menus.push({
          to: `/contacts/${phone.tenantId}/${phone.id}`,
          title: 'Contacts',
          icon: 'mdi-contacts',
          description: 'Access, search and create new contacts',
          hasNotification: false
        })
        const isWppPhoneNumber = !phone.enable_sms && phone.enable_whatsapp
        if (isWppPhoneNumber) {
          menus.push({
            to: `/whatsapp-templates/${phone.tenantId}/${phone.id}`,
            title: 'WhatsApp Templates',
            icon: 'mdi-whatsapp',
            description: 'Create and modify Whatsapp templates.',
            hasNotification: false
          })
        }
        const canViewCampaigns = phone.permissions?.includes(CAMPAINGS_PERMISSIONS.VIEW)
        if (phone.campaigns_enabled && canViewCampaigns) {
          menus.push({
            to: `/oneoff-campaigns/${phone.tenantId}/${phone.id}`,
            title: 'Campaigns',
            description: 'Create and modify existing campaigns',
            icon: 'mdi-bullhorn',
            hasNotification: false
          })
        }
        if (canViewCampaigns && (phone.campaigns_enabled || phone.drip_campaigns_enabled)) {
          menus.push({
            to: phone.drip_campaigns_enabled ? `/drip-campaigns/${phone.tenantId}/${phone.id}` : '/early-access/drip-campaigns/',
            title: 'Drip Campaigns',
            description: 'Create and modify existing drip campaigns',
            icon: 'mdi-multicast',
            hasNotification: false
          })
        }
        const showVoiceCallsPreview = tenantFeatures.value.includes('show_voice_calls')
        if (showVoiceCallsPreview) {
          menus.push({
            to: '/early-access/voice-calls',
            title: 'Calling',
            icon: 'mdi-phone-in-talk',
            description: 'Emissary is developing in-app calling capabilities to make candidate and employee communications easier and more efficient for our customers.',
            hasNotification: false
          })
        }
        return { ...phone, menus }
      }).sort(sortByInboxName)
    }
  })

  const userServiceRefresh : Refresher = {
    interval: null,
    enable () {
      this.interval = setInterval(() => {
        loadUserServices(true)
      }, 10 * MS_PER.Minute)
    },
    disable () {
      clearInterval(this.interval)
    }
  }

  const token = computed({
    get: () => user.value.token,
    set: (value) => {
      localStorage.setItem('auth-token', <string>value)
      user.value.token = value
    }
  })

  const resetState = () => {
    localStorage.removeItem('current-tenant')
    localStorage.removeItem('current-version')
    localStorage.removeItem('auth-token')
    user.value = getDefaultState()
    userServicesPromise.value = null
  }

  const activePhoneMenuFromPath = (path:any) => {
    return phoneMenus.value?.find(phoneRoute => phoneRoute.menus.some((menu:any) => path === menu.to))
  }

  const phoneMenuById = (id: any) => {
    return phoneMenus.value?.find(phoneRoute => phoneRoute.id?.toString() === id?.toString())
  }

  const phoneById = (id:string) => {
    return user.value.tenants?.map(tenant => tenant.phone_numbers)
      .flat().find(phone => phone.id?.toString() === id?.toString())
  }

  const phoneByNumber = (number:any) => {
    return user.value.tenants?.map(tenant => tenant.phone_numbers)
      .flat().find(phone => phone.phone_number?.toString() === number?.toString())
  }

  const tenantOwnerOfPhone = (phone:any) => {
    return user.value.tenants?.find(tenant => tenant.phone_numbers.map(({ phone_number }:any) => phone_number).includes(phone))
  }

  const currentTenantInfo = computed(() => {
    return user.value.tenants?.find(tenant => tenant.id?.toString() === currentTenantId.value?.toString())
  })

  const userName = (phone:any) => {
    return user.value.tenants?.find(tenant => tenant.phone_numbers.map(({ phone_number }:any) => phone_number).includes(phone))
  }

  const checkIsReadOnly = (id:string) => {
    return phoneById(id)?.read_only
  }

  const canSelectAllActivityOverviewSeats = computed(() => {
    return !!currentTenantInfo.value?.feature_flags?.includes('activity_overview_seat_selection')
  })

  const canUseTranslations = computed(() => {
    return !currentTenantInfo.value?.feature_flags?.includes('disable_translations')
  })

  const enforceAddOptOutMessage = computed(() => {
    return !!currentTenantInfo.value?.feature_flags.includes('ENFORCE_OPT_OUT')
  })

  const canResetPassword = computed(() => {
    return !currentTenantInfo.value?.feature_flags?.includes('disable_password')
  })

  const canUseVoiceCalls = computed(() => {
    return !!currentTenantInfo.value?.feature_flags?.includes('voice_call')
  })

  const getUserServicesPromise = async () => {
    if (phoneMenus.value) {
      // We want to load this asyncronously
      loadUserServices(false)
      return phoneMenus.value
    } else {
      await loadUserServices(false)
    }
  }

  const loadUserServices = async (skipLoading: boolean) => {
    try {
      userServiceRefresh.disable()
      user.value.loadingMenus = !skipLoading
      const services = await Service.api.userService!.getUserServices()
      user.value.tenants = services.tenants
      if (services.tenants) {
        Sentry.setContext('tenants', { tenants: JSON.stringify(services.tenants) })
      }
      let tenantId = currentTenantId.value || services.tenants[0]?.id
      const currentTenantWholeInfo = user.value.tenants.find(tenant => tenant.id?.toString() === tenantId?.toString())
      if (!currentTenantWholeInfo) {
        tenantId = services.tenants[0]?.id
      }
      if (tenantId) {
        setCurrentTenantInformation(tenantId)
      }
      const hasToForceEasternTz = !!currentTenantInfo.value?.feature_flags?.includes('force_eastern_tz')
      if (hasToForceEasternTz) {
        timeZoneStore.saveUserSettings({
          region: 'America',
          location: 'New York'
        })
      }
      user.value.loadingMenus = false
      return phoneMenus.value
    } catch (error:any) {
      if (error.response?.status !== 401) {
        dialog.warning({
          text: 'There was an unexpected problem while loading the user services. Please click the button reload to load them again',
          title: 'Oops',
          action: () => { location.reload() },
          dialogProps: {
            confirmText: 'Reload',
            canClose: false,
            showCancel: false
          }
        })
      }
      if (error.message !== 'Network Error') {
        Sentry.captureException(error)
      }
      return false
    } finally {
      userServiceRefresh.enable()
    }
  }

  const getUserServices = () => {
    if (userServicesPromise.value) {
      return userServicesPromise.value
    } else {
      const getUserServicesPromiseBinded = getUserServicesPromise.bind(this)
      const userServicesPromiseToSet = getUserServicesPromiseBinded()
      userServicesPromise.value = userServicesPromiseToSet
      return userServicesPromiseToSet
    }
  }

  const setCurrentTenantInformation = (tenantId: string) => {
    const currentTenant = user.value.tenants.find(tenant => tenant.id?.toString() === tenantId?.toString())
    const phoneNumberWithTenantId = (phone:any, tenant:any) => ({ ...phone, tenantId: tenant.id })
    const tenantPhoneMenus = currentTenant.phone_numbers
      .map((phone:any) => phoneNumberWithTenantId(phone, currentTenant))
    tenantPhoneMenus.sort((phoneMenuA:any, phoneMenuB:any) => phoneMenuA.is_seat - phoneMenuB.is_seat)
    phoneMenus.value = tenantPhoneMenus
    currentTenantId.value = tenantId
  }

  const getUserInformation = async () => {
    const token = localStorage.getItem('auth-token')
    if (!token || user.value.loadingUserInformation) {
      return false
    }
    user.value.loadingUserInformation = true
    try {
      const userService = Service.api.userService
      if (!userService) {
        snackbar.error('Could not load the user information.')
      }
      const response = await userService!.getUserInformation()
      user.value.userInfo = response.user
      if (response.user) {
        const { id, email, name } = response.user
        Sentry.setContext('user', { id, email, name })
        Sentry.setContext('feature_flags', { feature_flags: response.user.feature_flags })
      }
      if (user.value?.tenants?.length) {
        setCurrentTenantInformation(currentTenantId.value)
      }
      messages.value = response.messages.length > 0 ? response.messages : []
      return response.user
    } catch {
      snackbar.error('Could not get the user information.')
      return false
    } finally {
      user.value.loadingUserInformation = false
    }
  }

  const authenticate = async ({ email, password } : any) => {
    const response = await Service.api.userService!.authenticate(email, password)
    if (response.token) {
      token.value = response.token
    }
    return response
  }

  const refreshUserToken = () => {
    const existingToken = localStorage.getItem('auth-token')
    if (existingToken) {
      Service.api.userService!.refreshToken(existingToken).then((result) => {
        const { token: newToken } = result
        if (newToken) {
          token.value = newToken
        }
      }).catch(() => false)
    }
  }

  const goToInboxOrHome = async () => {
    const editableChromExtensionPrefs = useChromeExtensionStore().editable
    const editableExtension = editableChromExtensionPrefs.extension()
    const phoneMenus = await getUserServices()
    const router = useRouter()
    if (!phoneMenus) {
      router.push('/')
      return
    }
    const hasChromeExtension = editableExtension.value?.eid
    const firstMenuOfFirstPhone = get(phoneMenus, '0.menus.0.to')
    const canGoToInbox = phoneMenus?.length === 1 && firstMenuOfFirstPhone && !hasChromeExtension
    if (canGoToInbox) {
      router.push(firstMenuOfFirstPhone)
      return
    }
    if (hasChromeExtension) {
      router.push('/chrome-extension')
      return
    }
    router.push('/')
  }
  const logout = () => {
    const { logout: chromeExtensionLogout } = useChromeExtensionStore()
    const router = useRouter()
    return Service.api.userService!.logout().finally(() => {
      localStorage.removeItem('storedPhoneNumberId')
      localStorage.removeItem('auth-token')
      chromeExtensionLogout()
      Service.api.channelService!.resetPluginState()
      resetState()
      threadStore.resetState()
      localStorage.removeItem('user')
      user.value = getDefaultState()
      router.push('/login/' + window.location.search)
    })
  }

  const getAvailableLanguages = async () => {
    if (user.value.availableLanguages.length > 0) {
      return
    }
    try {
      const availableLanguages: Maybe<AvailableLanguage>[] | null = await Service.gql.threadGql!.getAvailableLanguages()
      user.value.availableLanguages = availableLanguages?.map((language : Maybe<AvailableLanguage>) => ({
        title: language?.name,
        value: language?.code,
        isPopular: language?.isPopular
      })) || []
    } catch (error) {
      snackbar.error('There was an error loading Available Languages.')
      Sentry.captureException(error)
    }
  }

  const refreshUserCache = () => {
    location.reload()
  }

  return {
    readable: {
      userInfo,
      loadingUserInformation,
      loadingMenus,
      tenants,
      phoneMenus,
      availableLanguages,
      locale,
      messages,
      isAdminOrOwner,
      OPT_OUT_MESSAGE,
      hasOOOPermission
    },
    editable: {
      currentTenantId,
      token
    },
    activePhoneMenuFromPath,
    phoneMenuById,
    phoneById,
    phoneByNumber,
    tenantOwnerOfPhone,
    currentTenantInfo,
    userName,
    checkIsReadOnly,
    canSelectAllActivityOverviewSeats,
    canUseTranslations,
    canResetPassword,
    canUseVoiceCalls,
    enforceAddOptOutMessage,
    getUserServices,
    setCurrentTenantInformation,
    getUserInformation,
    authenticate,
    refreshUserToken,
    goToInboxOrHome,
    logout,
    getAvailableLanguages,
    refreshUserCache
  }
})
