// TODO: rename this composable to useDateTimeAux or useDateTimeUtils

import { Interval, DateTime, DateTimeUnit, DurationLike } from 'luxon'
import { useTimeZoneStore } from '@/stores/timeZone'
import useDateTimeFormat from '@/composables/useDateTimeFormat'
import { MS_PER } from '@/services/types/Time'

export default () => {
  const { formatDuration, formatDateTime, toISODateTime } = useDateTimeFormat()
  const getDateTimeFileNameString = (): string => {
    const dateResult = new Date().toLocaleString('en-US', { hour12: false })
    return dateResult.replace(/[/:]/g, '_').replace(', ', '-')
  }
  const getTimeUnit = (time : number) : string | null => {
    const unitsPerTime = Object.entries(MS_PER)
    const sortByUnit = (units : Array<any>) => units.sort(([_unitName1, unitMillis1], [_unitName2, unitMillis2]) => unitMillis2 - unitMillis1)
    sortByUnit(unitsPerTime)
    const timeUnit = unitsPerTime.find(([_unitName, unitMillis] : Array<any>) => time >= unitMillis)
    return timeUnit ? timeUnit[0] : null
  }
  const getDifference = ({ isoStart, isoEnd, toFormat }: { isoStart: string, isoEnd: string, toFormat?: string}) => {
    const startDateTime = DateTime.fromISO(isoStart)
    const endDateTime = DateTime.fromISO(isoEnd)
    const difference = endDateTime.diff(startDateTime, ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'])
    return formatDuration(difference, toFormat)
  }
  const getRangeForYear = ({ year } : { year: number}) => {
    const timeZoneStore = useTimeZoneStore()
    const startDate = timeZoneStore.getUTCDateTimeFromObject({ object: { year, month: 1, day: 1 }, toFormat: 'isoDate' })
    const endDate = timeZoneStore.getUTCDateTimeFromObject({ object: { year, month: 12, day: 31 }, toFormat: 'isoDate' })
    return [startDate, endDate]
  }
  const getRangeForLast12Months = () => {
    const timeZoneStore = useTimeZoneStore()
    const oneYearAgoDate = timeZoneStore.getUTCDateTimeNow({ toFormat: 'isoDate', offset: { months: -12 } })
    const iso = timeZoneStore.getUTCDateTimeNow({ toFormat: 'isoDate' })
    const endOfThisMonthDate = getEndOfUnit({ iso, unit: 'month', toFormat: 'isoDate' })
    return [oneYearAgoDate, endOfThisMonthDate]
  }
  const getIntervalSplitBy = ({ isoStart, isoEnd, splitBy = { months: 1 }, toFormat } : { isoStart: string, isoEnd: string, splitBy?: DurationLike, toFormat?: string }) => {
    return Interval
      .fromISO(`${isoStart}/${isoEnd}`)
      .splitBy(splitBy)
      .map((dateTime: any) => formatDateTime(dateTime.start, toFormat))
  }
  const getStartOfUnit = ({ iso, unit, toFormat } : { iso: string, unit: DateTimeUnit, toFormat?: string }) => {
    const date = DateTime.fromISO(iso).startOf(unit)
    return formatDateTime(date, toFormat)
  }
  const getEndOfUnit = ({ iso, unit, toFormat } : { iso: string, unit: DateTimeUnit, toFormat?: string }) => {
    if (!iso) {
      throw new Error('Missing iso')
    }
    const date = DateTime.fromISO(iso).endOf(unit)
    return formatDateTime(date, toFormat)
  }
  const getTimeInUnits = (miliseconds: MS_PER) => {
    return {
      total: miliseconds,
      years: Math.floor(miliseconds / (MS_PER.Year)),
      months: Math.floor(miliseconds / (MS_PER.Month)),
      weeks: Math.floor(miliseconds / (MS_PER.Week)),
      days: Math.floor(miliseconds / (MS_PER.Day)),
      hours: Math.floor((miliseconds / (MS_PER.Hour)) % 24),
      minutes: Math.floor((miliseconds / MS_PER.Minute) % 60),
      seconds: Math.floor((miliseconds / MS_PER.Second) % 60)
    }
  }
  const toTimeStringFromMilis = (miliseconds: any) => {
    const {
      years,
      months,
      days,
      hours,
      minutes,
      seconds
    } = getTimeInUnits(miliseconds)
    const formatTimeUnit = (value: number, unit: string) => {
      return value === 0 ? '' : `${value} ${unit} `
    }
    if (years >= 1) {
      return `more than ${years} years`
    } else if (months >= 1) {
      return `${months} months ${formatTimeUnit(days, 'days')}`
    } else if (days >= 1) {
      return `${days} days ${formatTimeUnit(hours, 'hours')}`
    } else {
      const dayHourMinuteStr = `${formatTimeUnit(days, 'days')} ${formatTimeUnit(hours, 'hours')} ${formatTimeUnit(minutes, 'minutes')}`
      return `${dayHourMinuteStr ? `${dayHourMinuteStr} ${seconds}` : seconds} seconds`
    }
  }
  const getDateTimeDifference = (endDateTimeInMilliseconds: any) => {
    const nowDate = DateTime.now()
    const nowPlusEndDate = nowDate.plus({ milliseconds: endDateTimeInMilliseconds })
    const startDateTime = DateTime.fromISO(nowDate)
    const endDateTime = DateTime.fromISO(nowPlusEndDate)
    return endDateTime.diff(startDateTime, ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'])
  }
  return {
    toISODateTime,
    getDateTimeFileNameString,
    getTimeUnit,
    getDifference,
    getRangeForYear,
    getRangeForLast12Months,
    getIntervalSplitBy,
    getStartOfUnit,
    getEndOfUnit,
    toTimeStringFromMilis,
    getDateTimeDifference
  }
}
