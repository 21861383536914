import { get, set } from 'lodash'
import * as Sentry from '@sentry/browser'
import { defineStore } from 'pinia'
import { useTimeZoneStore } from './timeZone'
import { Thread, ThreadFilter } from '@/services/types/Thread'
import { Service } from '@/services/helpers/ServiceHelper'
import useSnackbar from '@/composables/useSnackbar'
import { MS_PER } from '~/services/types/Time'

type TranslationThread = Thread & { translation: { translated: boolean, translated_language: string } }
type MarkThreadParameters = {
  thread:Thread,
  phoneNumberId:string,
  tenantId:string,
  value: boolean,
  force?: boolean
}
type InboxDetail = {
        count: number,
        tenantId: string,
        phoneNumberId: string,
        threads: Array<any>,
        loadingThreads: boolean,
        inboxMeta: any,
        loadingInboxMeta: boolean,
        hasMoreThreads: boolean,
        filter: ThreadFilter,
        selectedThread: Thread | null,
        unreadThreads: number,
        unreadArchivedThreads: number,
        hasAnyContact: boolean
      }
const snackbar = useSnackbar()
export const useThreadStore = defineStore('thread', () => {
  const { path } = useRouteParams()
  const user = useUserStore()
  const unreadThreadsPromise = ref<Promise<any> | null>(null)
  const isRecentlyCreatedThread = (thread) => {
    const isRecentlyCreated = date => !!date && (new Date() - new Date(date)) < MS_PER.Minute * 15
    return isRecentlyCreated(thread.created)
  }
  const sortThreads = (threads: Array<Thread>) => {
    const getLastDate = thread => (thread.last_outgoing || '') > (thread.last_incoming || '')
      ? thread.last_outgoing
      : thread.last_incoming
    const orderedThreads = threads.sort((thread1, thread2) => {
      const thread1LastInteraction = getLastDate(thread1)
      const thread2LastInteraction = getLastDate(thread2)
      const isThread1NewAndRecent = isRecentlyCreatedThread(thread1)
      const isThread2NewAndRecent = isRecentlyCreatedThread(thread2)
      const hasMoreRecentInteraction = (thread1LastInteraction && thread1LastInteraction > thread2LastInteraction)
      const hasInteractionAndOtherDoesNot = (thread1LastInteraction && !thread2LastInteraction)
      if (isThread1NewAndRecent && isThread2NewAndRecent) {
        return hasMoreRecentInteraction || hasInteractionAndOtherDoesNot ? -1 : 1
      }
      if (isThread1NewAndRecent) {
        return -1
      }
      if (isThread2NewAndRecent) {
        return 1
      }
      return hasMoreRecentInteraction || hasInteractionAndOtherDoesNot ? -1 : 1
    })
    return orderedThreads
  }
  const addThreads = ({ inboxDetail, newThreads }: {inboxDetail: any, newThreads: Array<Thread>}) => {
    replaceWithSelectedThread(newThreads, inboxDetail)
    newThreads.forEach((newThread) => {
      if (newThread !== inboxDetail.selectedThread) {
        changeThreadDateToUserTimeZone(newThread)
      }
      const existingThread = inboxDetail.threads.find((thread: Thread) => thread.id === newThread.id)
      if (!existingThread) {
        inboxDetail.threads.push(newThread)
      } else {
        Object.assign(existingThread, newThread)
      }
    })
    Object.assign(inboxDetail.threads, sortThreads(inboxDetail.threads))
  }
  const timeZoneStore = useTimeZoneStore()
  const getUserDateTimeFromISO = (iso: string) => {
    if (!iso) { return null }
    const utcISO = iso.split('.')[0]
    return timeZoneStore.getUserDateTimeFromISO({ timeZone: timeZoneStore.userSettings.timeZone, iso: utcISO })
  }
  const changeThreadDateToUserTimeZone = (thread: Thread) => {
    thread.scheduled_sms = thread.scheduled_sms?.map(scheduledMessage => ({
      ...scheduledMessage,
      scheduled: getUserDateTimeFromISO(scheduledMessage.scheduled)
    }))
    thread.last_outgoing = <string>getUserDateTimeFromISO(thread.last_outgoing)
    thread.last_incoming = <string>getUserDateTimeFromISO(thread.last_incoming)
    thread.created = <string>getUserDateTimeFromISO(thread.created)
  }
  const replaceWithSelectedThread = (newThreads: Array<Thread>, inboxDetail: any) => {
    const existingThreadIdx = newThreads.findIndex(thread => thread.id === inboxDetail.selectedThread?.id)
    if (existingThreadIdx !== -1) {
      inboxDetail.selectedThread = newThreads[existingThreadIdx]
    }
  }
  const getFiltersFromInboxDetail = (inboxDetail: any): {filter: ThreadFilter, has2Filter: boolean} => {
    const filter = {
      messageType: inboxDetail.filter.messageType,
      threadText2Filter: inboxDetail.filter.threadText2Filter,
      threadTags2Filter: inboxDetail.filter.threadTags2Filter,
      threadLastSearchValue: inboxDetail.filter.threadLastSearchValue
    }
    const has2Filter = filter.threadText2Filter || filter.threadTags2Filter.length > 0
    return { filter, has2Filter }
  }
  const tenantInboxDetail = ref({})
  Object.values(tenantInboxDetail.value).forEach((tenant: any) => {
    Object.values(tenant).forEach((phoneNumber: any) => {
      replaceWithSelectedThread(phoneNumber.threads, phoneNumber)
    })
  })
  const threadsByTenantAndPhoneNumber = (tenantId: string, phoneNumberId: string): InboxDetail | undefined => get(tenantInboxDetail.value, `${tenantId}.${phoneNumberId}`)
  const threadById = (tenantId: string, phoneNumberId: string, threadId: string) => {
    const tenantPhoneNumber = threadsByTenantAndPhoneNumber(tenantId, phoneNumberId)
    if (!tenantPhoneNumber) { return null }
    return tenantPhoneNumber.selectedThread?.id === threadId
      ? tenantPhoneNumber.selectedThread
      : tenantPhoneNumber.threads
        .find(thread => thread.id?.toString() === threadId?.toString())
  }
  const getUnreadThreads = (tenantId: string, phoneNumberId: string, getArchived = false) => {
    const tenantPhoneNumber = threadsByTenantAndPhoneNumber(tenantId, phoneNumberId)
    if (!tenantPhoneNumber) { return 0 }
    return getArchived ? tenantPhoneNumber!.unreadArchivedThreads : tenantPhoneNumber!.unreadThreads
  }

  // ACTIONS
  const getThreadByIdOrChannelSid = async ({ threadId, tenantId, phoneNumberId, channelSid }: { threadId: string, tenantId: string, phoneNumberId: string, channelSid: string }) => {
    const inboxDetail = getInboxDetailOrInit({ tenantId, phoneNumberId })
    const thread = inboxDetail.threads
      .find(aThread => aThread.id === threadId || aThread.channel_sid === channelSid)
    if (thread) { return thread }
    const isOnImportantOrArchivedTab = inboxDetail.filter.messageType === 'important' || inboxDetail.filter.messageType === 'archived'
    const onlyGet = getFiltersFromInboxDetail(inboxDetail).has2Filter || isOnImportantOrArchivedTab
    const threadFromBackend = await getThread({ threadId, tenantId, phoneNumberId, onlyGet })
    return threadFromBackend
  }
  const getInboxDetailOrInit = ({ tenantId, phoneNumberId }: {tenantId: string, phoneNumberId: string}) => {
    let inboxDetail: InboxDetail | undefined = threadsByTenantAndPhoneNumber(tenantId, phoneNumberId)
    if (!inboxDetail) {
      inboxDetail = {
        count: 0,
        tenantId,
        phoneNumberId,
        threads: [],
        loadingThreads: true,
        inboxMeta: {},
        loadingInboxMeta: false,
        hasMoreThreads: true,
        filter: {
          messageType: 'all',
          threadText2Filter: '',
          threadLastSearchValue: '',
          threadTags2Filter: []
        },
        selectedThread: null,
        unreadThreads: 0,
        unreadArchivedThreads: 0,
        hasAnyContact: false
      }
      set(tenantInboxDetail.value, `${tenantId}.${phoneNumberId}`, inboxDetail)
    }
    return inboxDetail
  }
  const getInboxMeta = ({ tenantId, phoneNumberId }: {tenantId: string, phoneNumberId: string}) => {
    const inboxDetail = getInboxDetailOrInit({ tenantId, phoneNumberId })
    if (inboxDetail.loadingInboxMeta) {
      return Promise.resolve(false)
    }
    inboxDetail.loadingInboxMeta = true
    return Service.api.threadService!.getInboxMeta(tenantId, phoneNumberId)
      .then((response) => {
        inboxDetail.inboxMeta = response
        return response
      }).finally(() => {
        inboxDetail.loadingInboxMeta = false
      })
  }
  const setLoading = ({ tenantId, phoneNumberId, value }: {tenantId: string, phoneNumberId: string, value: boolean}) => {
    const inboxDetail = getInboxDetailOrInit({ tenantId, phoneNumberId })
    inboxDetail.loadingThreads = value
  }
  const sliceThreads = ({ tenantId, phoneNumberId }: { tenantId: string, phoneNumberId: string }) => {
    const inboxDetail = getInboxDetailOrInit({ tenantId, phoneNumberId })
    inboxDetail.threads = inboxDetail.threads.slice(0, Service.api.threadService?.getPageSize())
  }
  const selectThread = async ({ threadId, tenantId, phoneNumberId }: { threadId:string, tenantId:string, phoneNumberId:string }) => {
    const inboxDetail = getInboxDetailOrInit({ tenantId, phoneNumberId })
    if (!threadId) {
      inboxDetail.selectedThread = null
      return
    }
    let thread2Select = threadById(tenantId, phoneNumberId, threadId)
    if (!thread2Select) {
      thread2Select = await getThread({ threadId, tenantId, phoneNumberId, onlyGet: true })
    }
    markAsRead({ thread: thread2Select, phoneNumberId, tenantId, value: false })
    inboxDetail.selectedThread = thread2Select
    return thread2Select
  }
  const getThread = async ({ tenantId, phoneNumberId, threadId, onlyGet = false }: { tenantId:string, phoneNumberId:string, threadId:string, onlyGet:boolean }) => {
    const inboxDetail = getInboxDetailOrInit({ tenantId, phoneNumberId })
    const thread = threadById(tenantId, phoneNumberId, threadId)
    if (thread) {
      return thread
    }
    const threadInformation = await Service.api.threadService!
      .getThreadInformation({ tenantId, phoneNumberId, threadId })
    changeThreadDateToUserTimeZone(threadInformation)
    const isNoArchivedThreadAndIsAllTab = !threadInformation.archived && inboxDetail.filter.messageType === 'all'
    if (!onlyGet && isNoArchivedThreadAndIsAllTab) {
      addThreads({ inboxDetail, newThreads: [threadInformation] })
    }
    return threadInformation
  }
  const refreshThreadList = async ({ tenantId, phoneNumberId }: { tenantId:string, phoneNumberId:string }) => {
    const inboxDetail = getInboxDetailOrInit({ tenantId, phoneNumberId })
    const { filter, has2Filter } = getFiltersFromInboxDetail(inboxDetail)
    const existingThreadsAmount = inboxDetail.threads.length
    let response
    if (has2Filter) {
      response = await Service.api.threadService!
        .getThreadsByFilter(tenantId, phoneNumberId, filter, 0)
    } else {
      const marginToReload = 1
      const threads2Load = existingThreadsAmount + marginToReload
      response = await Service.api.threadService!.getThreads(tenantId, phoneNumberId, 0, {}, filter.messageType, threads2Load)
    }
    if (response?.results) {
      addThreads({ inboxDetail, newThreads: response.results })
      // We want to keep the same amount of threads to prevent the list from growing
      inboxDetail.threads = inboxDetail.threads.slice(0, existingThreadsAmount)
      inboxDetail.hasMoreThreads = !!response.next
    }
  }
  const getThreads = async ({ tenantId, phoneNumberId, reset = false, isFirstTime = false }: { tenantId:string, phoneNumberId:string, reset: boolean, isFirstTime: boolean}) => {
    const inboxDetail = getInboxDetailOrInit({ tenantId, phoneNumberId })
    initUnreadThreads({ tenantId, phoneNumberId })
    const { filter, has2Filter } = getFiltersFromInboxDetail(inboxDetail)
    if (reset) {
      replaceWithSelectedThread([], inboxDetail)
      inboxDetail.threads = []
      inboxDetail.hasMoreThreads = true
    }
    if (!inboxDetail.hasMoreThreads) {
      inboxDetail.hasMoreThreads = false
      return Promise.resolve(false)
    }
    inboxDetail.loadingThreads = true
    const existingThreadsAmount = inboxDetail.threads.length
    const processThreadError = (error: { response: { status: number } }) => {
      if (error.response?.status === 404) {
        return {
          next: false,
          results: [],
          count: inboxDetail.threads.length
        }
      }
      throw error
    }
    let response
    if (has2Filter) {
      response = await Service.api.threadService!
        .getThreadsByFilter(tenantId, phoneNumberId, filter, existingThreadsAmount)
        .catch(processThreadError)
    } else {
      response = await Service.api.threadService!.getThreads(tenantId, phoneNumberId, existingThreadsAmount, {}, filter.messageType)
        .catch(processThreadError)
    }
    if (isFirstTime) {
      inboxDetail.hasAnyContact = response.count > 0
      initUnreadThreads({ tenantId, phoneNumberId })
    }
    addThreads({ inboxDetail, newThreads: response.results })
    inboxDetail.hasMoreThreads = !!response.next
    inboxDetail.count = response.count
    inboxDetail.loadingThreads = false
    return response
  }
  const getUnreadThreadsPromise = async ({ tenantId, phoneNumberId }: { tenantId: string, phoneNumberId: string }) => {
    const tenantPhoneNumber = threadsByTenantAndPhoneNumber(tenantId, phoneNumberId)
    const { count: unreadThreads, results: threadsResults } = await Service.api.threadService!.getUnreadThreads(tenantId, phoneNumberId)
    const unreadArchivedThreads = threadsResults?.filter((thread: Thread) => thread.archived).length
    if (tenantPhoneNumber) {
      set(tenantPhoneNumber, 'unreadThreads', unreadThreads)
      set(tenantPhoneNumber, 'unreadArchivedThreads', unreadArchivedThreads)
    }
  }
  const initUnreadThreads = ({ tenantId, phoneNumberId }: { tenantId: string, phoneNumberId: string }) => {
    if (unreadThreadsPromise.value) {
      unreadThreadsPromise.value.then(() => { unreadThreadsPromise.value = null })
    } else {
      const getUnreadThreadsPromiseBinded = getUnreadThreadsPromise.bind(this)
      const unreadThreadsPromiseToSet = getUnreadThreadsPromiseBinded({ tenantId, phoneNumberId })
      unreadThreadsPromise.value = unreadThreadsPromiseToSet
    }
  }
  const refreshUnreadArchivedThreads = ({ tenantId, phoneNumberId, thread }: { tenantId: string, phoneNumberId: string, thread: Thread }) => {
    const tenantPhoneNumber = threadsByTenantAndPhoneNumber(tenantId, phoneNumberId)
    if (tenantPhoneNumber?.filter.messageType === 'archived') {
      set(tenantPhoneNumber, 'unreadArchivedThreads', tenantPhoneNumber.threads.filter(thread => thread.archived && thread.is_unread).length)
      return
    }
    const changeCounter = thread.archived && thread.is_unread
    const unreadArchivedThreads = (tenantPhoneNumber?.unreadArchivedThreads || 0) + (changeCounter ? 1 : -1)
    if (tenantPhoneNumber && unreadArchivedThreads >= 0) {
      set(tenantPhoneNumber, 'unreadArchivedThreads', unreadArchivedThreads)
    }
  }
  const updateUnreadCounters = (tenantId: string, phoneNumberId: string, isRead: Boolean) => {
    const tenantPhoneNumber = threadsByTenantAndPhoneNumber(tenantId, phoneNumberId)
    const unreadThreads = (tenantPhoneNumber?.unreadThreads || 0) + (isRead ? 1 : -1)
    if (tenantPhoneNumber) {
      set(tenantPhoneNumber, 'unreadThreads', unreadThreads)
    }
  }
  const setLastMessage = ({ isRead, thread, lastMessage, lastMessageTranslated, lastUpdated, isDelivered, tenantId, phoneNumberId }:
    { isRead: boolean, thread: Thread, lastMessage:string, lastMessageTranslated:string, lastUpdated:string, isDelivered: boolean, tenantId:string, phoneNumberId:string }) => {
    const inboxDetail = getInboxDetailOrInit({ tenantId, phoneNumberId })
    const threadInList = inboxDetail.threads.find(t => t.id === thread.id)
    if (threadInList) {
      threadInList.is_unread = !isRead
    }
    if (!threadInList && thread.archived) {
      initUnreadThreads({ tenantId, phoneNumberId })
      return
    }
    const counterNeedsToBeUpdated = thread.is_unread === isRead
    if (counterNeedsToBeUpdated) {
      updateUnreadCounters(tenantId, phoneNumberId, !isRead)
    } else {
      initUnreadThreads({ tenantId, phoneNumberId })
    }
    if (threadInList) {
      threadInList.preview_text = lastMessage
      if (lastMessageTranslated) {
        threadInList.translation.preview_text_translated = lastMessageTranslated
      }
    }
    if (isDelivered) {
      thread.last_outgoing = lastUpdated
    } else {
      thread.last_incoming = lastUpdated
    }
  }
  const markAsRead = async ({ thread, phoneNumberId, tenantId, value, force = false }: MarkThreadParameters) => {
    if (thread.is_unread === value && !force) { return }
    try {
      const originalUnreadValue = thread.is_unread
      const existingThread = threadById(tenantId, phoneNumberId, thread.id)
      if (existingThread) {
        const counterNeedsToBeUpdated = thread.is_unread !== value
        if (existingThread) {
          existingThread.is_unread = value
        }
        if (counterNeedsToBeUpdated) {
          await updateUnreadCounters(tenantId, phoneNumberId, value)
        }
      }
      if (originalUnreadValue !== value) {
        await Service.api.threadService!.markAsRead({ tenantId, phoneNumberId, threadId: thread.id }, value)
      }
      if (thread.archived) {
        await refreshUnreadArchivedThreads({ tenantId, phoneNumberId, thread })
      }
    } catch (error) {
      Sentry.captureException(error)
      snackbar.error('Could not mark the chat as read.')
    }
  }
  const unsubscribeContact = async ({ contactId, phoneNumberId, thread, tenantId, threadId, blocked, isBlacklisted } :
  { contactId:string, phoneNumberId:string, thread: Thread, tenantId: string, threadId: string, blocked: boolean, isBlacklisted: boolean }) => {
    const existingThread = threadById(tenantId, phoneNumberId, threadId)
    try {
      await Service.api.contactService!.unsubscribeContact({ tenantId, phoneNumberId, contactId }, blocked, isBlacklisted)
      const thread2Unsuscribe = existingThread || thread
      thread2Unsuscribe.contact.is_blacklisted = isBlacklisted
      thread2Unsuscribe.contact.blocked = blocked
      thread2Unsuscribe.contact.client_can_optin = true
      return true
    } catch (ex) {
      snackbar.error('Could not unsubscribe contact.')
    }
  }
  const markAsStarred = ({ thread, phoneNumberId, tenantId, value }: MarkThreadParameters) => {
    const existingThread = threadById(tenantId, phoneNumberId, thread.id)
    if (existingThread) {
      existingThread.is_starred = value
    }
    return Service.api.threadService!.markAsStarred({ tenantId, phoneNumberId, threadId: thread.id }, value)
  }
  const markAsArchived = async ({ thread, phoneNumberId, tenantId, value }: MarkThreadParameters) => {
    try {
      await (value
        ? Service.gql.threadGql!.archive(tenantId, thread.id)
        : Service.gql.threadGql!.unarchive(tenantId, thread.id))
      const existingThread = threadById(tenantId, phoneNumberId, thread.id)
      if (existingThread) {
        existingThread.archived = value
      }
      const inboxDetail = getInboxDetailOrInit({ tenantId, phoneNumberId })
      if ((inboxDetail.filter.messageType === 'archived' && !value) || (inboxDetail.filter.messageType !== 'archived' && value)) {
        inboxDetail.threads = inboxDetail.threads.filter(threadFromInbox => threadFromInbox.id !== thread.id)
      } else {
        addThreads({ inboxDetail, newThreads: [thread] })
      }
      await refreshUnreadArchivedThreads({ tenantId, phoneNumberId, thread })
    } catch (error) {
      Sentry.captureException(error)
      snackbar.error(`There was a problem while ${value ? 'archiving' : 'unarchiving'} the conversation.`)
    }
  }
  const createFromContact = async ({ contact, tenantId, phoneNumberId }: { contact: any, tenantId: string, phoneNumberId: string }) => {
    const inboxDetail = getInboxDetailOrInit({ tenantId, phoneNumberId })
    const phoneMenu = user.activePhoneMenuFromPath(path.value)
    const result = await Service.api.threadService!.create(tenantId, phoneNumberId, {
      phone_number: contact.phone,
      name: contact.name,
      email: contact.email
    })
    // TODO ask fernando to remove this flag and send the deafult outgoing protocol from BE correctly
    const newThread = { ...result?.thread, default_outgoing_protocol: !phoneMenu?.enable_sms && phoneMenu?.enable_whatsapp ? 'whatsapp' : null }
    // Since new threads are created as read, unimportant and unarchived
    const matchesWithFilter = inboxDetail.filter.messageType === 'all'
    if (matchesWithFilter) {
      addThreads({ inboxDetail, newThreads: [newThread] })
      inboxDetail.count = inboxDetail.count + 1
    }
    return result
  }
  const clearScheduledMessages = ({ thread }: { thread: Thread }) => {
    thread.scheduled_sms = []
  }
  const removeScheduledMessage = async ({ tenantId, phoneNumberId, thread, scheduledMessage }: { tenantId: string, phoneNumberId: string, thread: Thread, scheduledMessage: any }) => {
    const result = await Service.api.smsService!
      .deleteScheduleMessage({ tenantId, phoneNumberId, threadId: thread.id }, scheduledMessage.id)
    thread.scheduled_sms = thread.scheduled_sms.filter(sm => sm !== scheduledMessage)
    return result
  }
  const addScheduledMessage = async ({ tenantId, phoneNumberId, thread, message2Schedule }:
    { tenantId: string, phoneNumberId: string, thread: Thread, message2Schedule: any }) => {
    const message2ScheduleOnUTC = {
      ...message2Schedule,
      scheduled: timeZoneStore.getUTCDateTimeFromISO({ timeZone: timeZoneStore.userSettings.timeZone, iso: message2Schedule.scheduled })
    }
    const result = await Service.api.smsService!.scheduleMessage({ tenantId, phoneNumberId, threadId: thread.id }, message2ScheduleOnUTC)
    const scheduledMessage = {
      body: message2Schedule.body,
      scheduled: message2Schedule.scheduled,
      id: result.id,
      cancel_on_reply: message2Schedule.cancel_on_reply,
      translated_body: message2Schedule.translated_body
    }
    thread.scheduled_sms.push(scheduledMessage)
  }
  const updateContact = async ({ originalContact, contact, tenantId, phoneNumberId, dontWait = false }:
    { originalContact: any, contact: any, tenantId: string, phoneNumberId: string, dontWait: boolean}) => {
    const tagsWithoutcommas = contact.tags?.map((tag: string) => tag.replace(/,/g, ' ').toLowerCase())
    contact.tags = tagsWithoutcommas
    const contactUpdate = Service.api.contactService!.save(tenantId, phoneNumberId, contact)
    const updatedContact = dontWait ? contact : (await contactUpdate)
    if (originalContact) {
      Object.assign(originalContact, updatedContact)
    }
    const inboxDetail = threadsByTenantAndPhoneNumber(tenantId, phoneNumberId)
    if (inboxDetail) {
      const thread = inboxDetail.threads.find(thread => thread?.contact.id?.toString() === contact.id?.toString())
      if (thread) {
        Object.assign(thread.contact, updatedContact)
      }
    }
    return updatedContact
  }
  const toggleTranslation = async ({ thread, enable }: {thread: TranslationThread, enable: boolean}) => {
    const { response, translatedLanguage } = await Service.gql.threadGql!.toggleTranslation(thread.id, enable)
    if (thread.translation) {
      thread.translation.translated = response
      thread.translation.translated_language = translatedLanguage
    }
  }
  const changeTranslationLanguage = async ({ thread, language }: {thread: TranslationThread, language: string}) => {
    await Service.gql.threadGql!.changeTranslationLanguage(thread.id, language)
    if (thread.translation) { thread.translation.translated_language = language }
    return true
  }
  const updateThreadLanguage = ({ thread, language }: {thread: TranslationThread, language: string }) => {
    if (thread.translation) { thread.translation.translated_language = language }
  }
  const setThreads2Filter = ({ tenantPhoneNumber, value }: { tenantPhoneNumber: InboxDetail, value:string }) => {
    tenantPhoneNumber.filter.threadText2Filter = value
  }
  const setThreadTags2Filter = ({ tenantPhoneNumber, value }: { tenantPhoneNumber: InboxDetail, value: Array<any> }) => {
    tenantPhoneNumber.filter.threadTags2Filter = value
  }
  const setMessageType = ({ tenantPhoneNumber, value }: { tenantPhoneNumber: InboxDetail, value: string }) => {
    tenantPhoneNumber.filter.messageType = value
  }
  const resetState = () => {
    tenantInboxDetail.value = {}
  }
  const setThreadLastSearchValue = ({ tenantPhoneNumber, lastSearchValue }: { tenantPhoneNumber: InboxDetail, lastSearchValue:string }) => {
    tenantPhoneNumber.filter.threadLastSearchValue = lastSearchValue
  }
  return {
    threadsByTenantAndPhoneNumber,
    threadById,
    getUnreadThreads,
    changeTranslationLanguage,
    updateThreadLanguage,
    toggleTranslation,
    updateContact,
    addScheduledMessage,
    removeScheduledMessage,
    clearScheduledMessages,
    createFromContact,
    markAsRead,
    unsubscribeContact,
    markAsArchived,
    markAsStarred,
    getThreads,
    getThread,
    refreshThreadList,
    setLastMessage,
    setThreadLastSearchValue,
    initUnreadThreads,
    getInboxMeta,
    selectThread,
    setLoading,
    sliceThreads,
    getInboxDetailOrInit,
    getThreadByIdOrChannelSid,
    setThreads2Filter,
    setThreadTags2Filter,
    setMessageType,
    resetState,
    getFiltersFromInboxDetail,
    isRecentlyCreatedThread
  }
})
