import * as Sentry from '@sentry/browser'
import { defineStore } from 'pinia'

const MB_IN_BYTES = 1024 * 1024
const MEMORY_THRESHOLD = MB_IN_BYTES * 700

export const useReportsStore = defineStore('reports', () => {
  const hasReported2Sentry = ref(false)

  const checkMemoryUsage = () => {
    const memoryInUse = window.performance?.memory?.usedJSHeapSize
    if (memoryInUse && memoryInUse > MEMORY_THRESHOLD && !hasReported2Sentry.value) {
      hasReported2Sentry.value = true
      Sentry.captureException(new Error(`Memory usage is ${memoryInUse / MB_IN_BYTES} MB`))
    }
  }
  return { checkMemoryUsage }
})
