import { defineStore } from 'pinia'
import * as Sentry from '@sentry/browser'
import { get, toLower } from 'lodash'
import { Service } from '@/services/helpers/ServiceHelper'
import useSnackbar from '@/composables/useSnackbar'
import { WriteFileParams } from '~/services/injectables/capacitor/FileSystemCapacitor'

type DevicePrefs = {
  isMobileDevice: boolean | null | undefined,
  platform: string | null | undefined,
  isAppOrWebWithMobile: boolean | null,
  externalAuthLoginDeviceInfo: any | null,
  skipMobileInvite: boolean
}

const getDefaultState = () => ({
  isMobileDevice: null,
  platform: null,
  isAppOrWebWithMobile: null,
  externalAuthLoginDeviceInfo: null,
  skipMobileInvite: localStorage.getItem('skipMobileInvite') === 'true'
} as DevicePrefs)

const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  }) as Promise<string | ArrayBuffer | null>
}
const generateAndDownloadFile = (file: { download: string, href: string }) => {
  const link = document.createElement('a')
  link.download = file.download
  link.href = file.href
  link.click()
}

export const useDeviceStore = defineStore('device', () => {
  const snackbar = useSnackbar()
  // STATE
  const devicePrefs = ref(getDefaultState())
  const platform = () => computed({
    get: () => devicePrefs.value.platform,
    set: (value) => { devicePrefs.value.platform = value }
  })
  const externalAuthLoginDeviceInfo = () => computed({
    get: () => devicePrefs.value.externalAuthLoginDeviceInfo,
    set: (value) => { devicePrefs.value.externalAuthLoginDeviceInfo = value }
  })
  const skipMobileInvite = () => computed({
    get: () => devicePrefs.value.skipMobileInvite,
    set: (value) => {
      localStorage.setItem('skipMobileInvite', value.toString())
      devicePrefs.value.skipMobileInvite = value
    }
  })
  // ACTIONS
  const downloadAsPdf = async ({ filename, content }: { filename: string, content: Blob | undefined }) => {
    if (!content) { return }
    const blob = new Blob([content], { type: 'application/pdf' })
    const data = await blobToBase64(blob) as string
    if (await isMobileDevice()) {
      await writeFile({ filename, data, encoding: 'Base64' as WriteFileParams['encoding'] })
    } else {
      generateAndDownloadFile({ download: filename, href: data as string })
    }
  }
  const downloadAsCsv = async ({ filename, data } : { filename: string, data: string }) => {
    try {
      if (await isMobileDevice()) {
        await writeFile({ filename, data, encoding: 'UTF8' as WriteFileParams['encoding'] })
      } else {
        data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data)
        generateAndDownloadFile({ download: filename, href: data })
      }
      snackbar.success(`The file "${filename}" has been successfully downloaded.`)
    } catch (error) {
      Sentry.captureException(error)
      snackbar.error('An error ocurred when trying to download the CSV file.')
    }
  }
  const addExternalAuthLoginDeviceInfo = (deviceInfo: string) => {
    externalAuthLoginDeviceInfo().value = deviceInfo
  }
  const doIfNotMobile = async (callback: Function) => {
    if (await isMobileDevice()) { return }
    callback()
  }
  const openBrowserWith = async ({ url, windowName }: { url: string, windowName: string}) => {
    if (await isMobileDevice()) {
      return Service.capacitor.browserCapacitor?.openBrowserWith(url, windowName)
    } else {
      window.location.replace(url)
      return false
    }
  }
  const copyToClipboard = async (payload: { value: string, text: string } | undefined) => {
    try {
      if (await isMobileDevice()) {
        await Service.capacitor.clipboardCapacitor?.copyToClipboard(payload?.value)
      } else {
        await navigator.clipboard.writeText(payload?.value as string)
      }
      if (payload?.text) {
        snackbar.notify(`${payload.text} copied to clipboard.`)
      } else {
        snackbar.notify('Copied to clipboard.')
      }
    } catch {
      snackbar.error('Could not copy to clipboard.')
    }
  }
  const addDeepLinking = async () => {
    const { checkAndUpdatePermission, clearAllNotifications } = usePushNotificationStore()
    if (!(await isMobileDevice())) { return }
    Service.capacitor.appCapacitor?.addAppListeners([
      {
        event: 'appUrlOpen',
        handler: (event: any) => {
          deepLinkingOnUrlOpen(event)
        }
      },
      {
        event: 'appStateChange',
        handler: ({ isActive } : { isActive: boolean }) => {
          if (isActive) {
            checkAndUpdatePermission()
            clearAllNotifications()
          }
        }
      }
    ])
  }
  const closeAppBrowser = async () => {
    if (!(await isMobileDevice())) { return }
    Service.capacitor.browserCapacitor?.close()
  }
  const deepLinkingOnUrlOpen = (event : { url: string }) => {
    const router = useRouter()
    const splittedUrl = event.url.split('')
    const mobileAuthUrlDomain = import.meta.env.VITE_WEB_APP_URL_DOMAIN.replace(/^https?:\/\//, '')
    const indexOfMobileAuthUrl = event.url.indexOf(mobileAuthUrlDomain)
    const slug = splittedUrl
      .slice(indexOfMobileAuthUrl + mobileAuthUrlDomain.length)
      .join('')
      .replace(/^\//, '')
    if (slug) {
      const route = `/${slug}`.replace('//', '/')
      router.push(route)
      return
    }
    router.push({
      path: '/'
    })
  }
  const redirectToWebAppForAuth = async (loginMeta: { email: string }) => {
    const email = loginMeta?.email || ''
    const browserUrl = import.meta.env.VITE_WEB_APP_URL_DOMAIN.endsWith('/')
      ? `${import.meta.env.VITE_WEB_APP_URL_DOMAIN}app-login/?comesFromPlatform=${devicePrefs.value.platform}&email=${encodeURIComponent(email)}`
      : `${import.meta.env.VITE_WEB_APP_URL_DOMAIN}/app-login/?comesFromPlatform=${devicePrefs.value.platform}&email=${encodeURIComponent(email)}`
    await openBrowserWith({ url: browserUrl, windowName: 'Emissary Login' })
  }
  const isMobileDevice = async () => {
    if (devicePrefs.value.isMobileDevice === null) {
      try {
        devicePrefs.value.isMobileDevice = !!get(navigator, 'app')
        await new Promise(resolve => setTimeout(resolve, 1000))
        const platformInformation = await Service.capacitor.deviceCapacitor?.getDevicePlatformInfo()
        devicePrefs.value.isMobileDevice = platformInformation?.isMobile
        devicePrefs.value.platform = platformInformation?.platform
      } catch (error) {
        Sentry.captureException(error)
      }
    }
    return devicePrefs.value.isMobileDevice
  }
  const isBrowserMobile = () => {
    return isBrowserIOS() || isBrowserAndroid()
  }
  const isBrowserIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !get(window, 'MSStream')
  }

  const isBrowserAndroid = () => {
    return /Android/i.test(navigator.userAgent)
  }

  const isAppOrWebWithMobile = async () => {
    const isAppOrWebWithMobile = (await isMobileDevice()) || isBrowserMobile()
    devicePrefs.value.isAppOrWebWithMobile = isAppOrWebWithMobile
    return isAppOrWebWithMobile
  }
  const writeFile = async (file: WriteFileParams) => {
    if (!(await isMobileDevice())) { return }
    await Service.capacitor.fileSystemCapacitor?.writeFile(file)
  }

  const link = {
    appStore: 'https://apps.apple.com/us/app/emissary-text-recruiting/id1487768297',
    playStore: 'https://play.google.com/store/apps/details?id=com.emissary.android'
  }
  const { query } = useRouteParams()
  const inviteToDownloadIfMobileBrowser = async () => {
    const isMobile = await isMobileDevice()
    const isMobileOrLoginFromMobile = isMobile || ['ios', 'android'].includes(toLower(query.value.comesFromPlatform))
    if (!isMobileOrLoginFromMobile && isBrowserMobile() && !skipMobileInvite().value) {
      const dialog = useDialog()
      dialog.info({
        text: 'It appears you\'re accessing our services from a mobile device. Did you know there\'s a convenient Emissary mobile app available? Simply click the button below to swiftly download the app and enjoy an enhanced experience.',
        title: 'Check our mobile App',
        action: () => {
          const urlToRedirect = isBrowserIOS() ? link.appStore : link.playStore
          location.href = urlToRedirect
        },
        cancelAction: () => {
          skipMobileInvite().value = true
        },
        dialogProps: { confirmText: 'Download app', cancelText: 'Keep using web version' }
      })
    }
  }

  return {
    readable: {
      platform,
      devicePrefs
    },
    link,
    inviteToDownloadIfMobileBrowser,
    isMobileDevice,
    isBrowserMobile,
    isBrowserIOS,
    isBrowserAndroid,
    downloadAsPdf,
    downloadAsCsv,
    doIfNotMobile,
    copyToClipboard,
    addDeepLinking,
    isAppOrWebWithMobile,
    closeAppBrowser,
    redirectToWebAppForAuth,
    addExternalAuthLoginDeviceInfo,
    writeFile
  }
})
