// TODO: change all 'new Date api' stuff to 'Luxon api'

import { defineStore } from 'pinia'
import useDialog from '@/composables/useDialog'

type ShowReminderProps = {
  title: string,
  text: string,
  action: () => void
}

export const useRemindersStore = defineStore('reminders', () => {
  const dialog = useDialog()
  // Internals
  const isAndroid = navigator.userAgent.toLowerCase().includes('android')
  const isIOS = navigator.userAgent.toLowerCase().includes('iphone')
  const notificationsReminder = ref(localStorage.getItem('notificationsReminder') || null)
  const contactsReminder = ref(localStorage.getItem('contactsReminder') || null)
  const getReminderDate = () => {
    const reminderDate = new Date()
    const NUMBER_OF_DAYS_TO_ADD = 15
    reminderDate.setDate(reminderDate.getDate() + NUMBER_OF_DAYS_TO_ADD)
    return reminderDate.toString()
  }
  const showReminder = ({ title, text, action }: ShowReminderProps) => {
    dialog.info({
      title,
      text,
      action,
      dialogProps: {
        confirmText: 'Understood',
        showCancel: false,
        confirmButtonProps: {
          block: true
        }
      }
    })
  }

  // Notifications reminder
  const setNotificationsReminder = () => {
    const reminderDate = getReminderDate()
    notificationsReminder.value = reminderDate
    localStorage.setItem('notificationsReminder', reminderDate)
  }
  const disableNotificationsReminder = () => {
    notificationsReminder.value = null
    localStorage.removeItem('notificationsReminder')
  }
  const showNotificationsReminder = () => {
    if (!notificationsReminder.value || new Date() < new Date(notificationsReminder.value)) {
      return
    }
    showReminder({
      title: 'Notifications',
      text: isAndroid
        ? 'We use notifications to allow you to receive messages from your Emissary contacts. \nPlease grant us permission to send you notifications by following the next steps: \nGo to your cellphone\'s configuration -> Apps -> Select Emissary app -> Enable "Notifications" permission.'
        : isIOS ? 'We use notifications to allow you to receive messages from your Emissary contacts. \nPlease grant us permission to send you notifications by following the next steps: \nGo to your cellphone\'s settings -> Emissary App -> Allow notifications.' : '',
      action: setNotificationsReminder
    })
  }

  // Contacts reminder
  const setContactsReminder = () => {
    const reminderDate = getReminderDate()
    contactsReminder.value = reminderDate
    localStorage.setItem('contactsReminder', reminderDate)
  }
  const disableContactsReminder = () => {
    contactsReminder.value = null
    localStorage.removeItem('contactsReminder')
  }
  const showContactsReminder = () => {
    if (!contactsReminder.value || new Date() < new Date(contactsReminder.value)) {
      return
    }
    showReminder({
      title: 'Contacts',
      text: isAndroid
        ? 'We use your contacts to allow you to send messages to your own contacts through the Emissary App. \nPlease grant us permission by following the next steps: \nGo to your cellphone\'s configuration -> Apps -> Select Emissary app -> Enable "Contacts" permission.'
        : isIOS ? 'We use your contacts to allow you to send messages to your own contacts through the Emissary App. \nPlease grant us permission by following the next steps: \nGo to your cellphone\'s settings -> Emissary App -> Allow contacts.' : '',
      action: setContactsReminder
    })
  }

  return {
    setNotificationsReminder,
    disableNotificationsReminder,
    showNotificationsReminder,
    setContactsReminder,
    disableContactsReminder,
    showContactsReminder
  }
})
