// Axios
import type { NuxtAxiosInstance } from '@nuxtjs/axios'

// API
import ContactService from '@/services/injectables/api/ContactService'
import IntegrationService from '@/services/injectables/api/IntegrationService'
import OneoffCampaignService from '@/services/injectables/api/OneOffCampaignService'
import PushNotificationsService from '@/services/injectables/api/PushNotificationsService'
import ReleaseService from '@/services/injectables/api/ReleaseService'
import ManageUsersService from '@/services/injectables/api/ManageUsersService'
import SmsService from '@/services/injectables/api/SmsService'
import StatService from '@/services/injectables/api/StatService'
import StatusService from '@/services/injectables/api/StatusService'
import TemplateService from '@/services/injectables/api/TemplateService'
import ThreadService from '@/services/injectables/api/ThreadService'
import TwilioProxyService from '@/services/injectables/api/TwilioProxyService'
import UrlShortenerService from '@/services/injectables/api/UrlShortenerService'
import UserService from '@/services/injectables/api/UserService'
import IdTranslatorService from '@/services/injectables/api/IdTranslatorService'
import ChannelService from '@/services/injectables/api/ChannelService'
import CronofyService from '@/services/injectables/api/CronofyService'
import CampaignExportService from '@/services/injectables/api/CampaignExportService'
import NylasService from '@/services/injectables/api/NylasService'
import CallService from '@/services/injectables/api/CallService'
// Capacitor
import AppCapacitor from '@/services/injectables/capacitor/AppCapacitor'
import BrowserCapacitor from '@/services/injectables/capacitor/BrowserCapacitor'
import ClipboardCapacitor from '@/services/injectables/capacitor/ClipboardCapacitor'
import ContactCapacitor from '@/services/injectables/capacitor/ContactCapacitor'
import DeviceCapacitor from '@/services/injectables/capacitor/DeviceCapacitor'
import FileSystemCapacitor from '@/services/injectables/capacitor/FileSystemCapacitor'
import LocalNotificationCapacitor from '@/services/injectables/capacitor/LocalNotificationCapacitor'
import PushNotificationCapacitor from '@/services/injectables/capacitor/PushNotificationCapacitor'
import PreferencesCapacitor from '@/services/injectables/capacitor/PreferencesCapacitor'
import StorageCapacitor from '@/services/injectables/capacitor/StorageCapacitor'

// GQL
import UtilsGql from '@/services/injectables/gql/UtilsGql'
import DripCampaignGql from '@/services/injectables/gql/DripCampaignGql'
import ContactGql from '@/services/injectables/gql/ContactGql'
import StatsGql from '@/services/injectables/gql/StatsGql'
import ThreadGql from '@/services/injectables/gql/ThreadGql'
import NotificationsGql from '@/services/injectables/gql/NotificationsGql'
import ManageUsersGql from '@/services/injectables/gql/ManageUsersGql'
import TimezoneGql from '@/services/injectables/gql/TimezoneGql'
import OutOfOfficeGql from '@/services/injectables/gql/OutOfOfficeGql'

export class Service {
  static api: {
    contactService?: ContactService
    channelService?: ChannelService
    integrationService?: IntegrationService
    oneOffCampaignService?: OneoffCampaignService
    pushNotificationsService?: PushNotificationsService
    manageUsersService?: ManageUsersService
    smsService?: SmsService
    statService?: StatService
    releaseService?: ReleaseService
    statusService?: StatusService
    templateService?: TemplateService
    threadService?: ThreadService
    twilioProxyService?: TwilioProxyService
    urlShortenerService?: UrlShortenerService
    userService?: UserService
    idTranslatorService?: IdTranslatorService
    cronofyService?: CronofyService
    campaignExportService?: CampaignExportService
    nylasService?: NylasService
    callService?: CallService
  } = {}

  static capacitor: {
    appCapacitor?: AppCapacitor
    browserCapacitor?: BrowserCapacitor
    clipboardCapacitor?: ClipboardCapacitor
    contactCapacitor?: ContactCapacitor
    deviceCapacitor?: DeviceCapacitor
    fileSystemCapacitor?: FileSystemCapacitor
    localNotificationCapacitor?: LocalNotificationCapacitor
    pushNotificationCapacitor?: PushNotificationCapacitor
    preferencesCapacitor?: PreferencesCapacitor
    storageCapacitor?: StorageCapacitor
  } = {}

  static gql: {
    utilsGql?: UtilsGql
    dripCampaignGql?: DripCampaignGql
    notificationsGql?: NotificationsGql
    contactGql?: ContactGql
    statsGql?: StatsGql
    threadGql?: ThreadGql
    manageUsersGql?: ManageUsersGql
    timezoneGql?: TimezoneGql
    outOfOfficeGql?: OutOfOfficeGql
  } = {}

  static $axiosInstance: NuxtAxiosInstance
}
