import { formatNumber, parsePhoneNumber, NumberFormat, PhoneNumber } from 'libphonenumber-js'

export default () => {
  const phone = (value: string, format: NumberFormat = 'NATIONAL'): string => {
    if (!value || value.length === 5) {
      return value
    }
    try {
      const parsed: PhoneNumber = parsePhoneNumber(value)
      if (parsed.country !== 'US' && format === 'NATIONAL') {
        // Still format non-US numbers internationally
        return parsed.formatInternational()
      }
      return formatNumber(value, format)
    } catch {
      return value
    }
  }
  // fixDirection: otherwise css "direction: rtl" would reverse the symbols order
  const fixDirection = (value: string): string => value ? value.split(' ').reverse().join(' ') : value
  const extensionPhone = (value: string): string => {
    const cleaned: string = ('' + value).replace(/\D/g, '')
    const match: RegExpMatchArray | null = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return `(${match[2]}) ${match[3]}-${match[4]}`
    }
    return value
  }
  const NO_LETTERS_AND_NUMBERS_REGEX = /[^a-zA-Z0-9]/g
  const onlyLettersAndNumbers = (value: string): string => !value ? value : value.replace(NO_LETTERS_AND_NUMBERS_REGEX, '').toLowerCase()
  return {
    phone,
    fixDirection,
    extensionPhone,
    onlyLettersAndNumbers
  }
}
