import { DateTime, DurationLike, DateObjectUnits } from 'luxon'
import { defineStore } from 'pinia'
import { useThreadStore } from './thread'
import useDateTimeFormat from '@/composables/useDateTimeFormat'

type UserSettings = {
  region: string,
  location: string
}
type Format = {
  date: string,
  time: string
}
export const useTimeZoneStore = defineStore('timeZone', () => {
  const { formatDateTime } = useDateTimeFormat()
  const threadStore = useThreadStore()
  // AUXILIARY
  // TODO: Remove this once google chorme fixes Intl.DateTimeFormat().resolvedOptions().timeZone
  const getSystemTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York'
  const getTimeZone = ({ region, location }: { region: string, location: string }) => {
    if (region === 'UTC') {
      return 'UTC'
    }
    const [city, area] = location.split(' (')[0].split(' / ').reverse()
    const formattedLocation = area ? `${area}/${city}` : city
    return `${region}/${formattedLocation}`.replaceAll(' ', '_')
  }
  const getRegion = ({ timeZone }: { timeZone: string }) => timeZone.replaceAll('_', ' ').split('/')[0]
  const getOffsetName = ({ timeZone, iso }: { timeZone: string, iso?: string }) => {
    const dateTime = iso ? DateTime.fromISO(iso, { zone: timeZone }) : DateTime.local().setZone(timeZone)
    return dateTime.offsetNameShort
  }
  const getLocation = ({ timeZone, iso }: { timeZone: string, iso?: string }) => {
    if (timeZone === 'UTC') {
      return 'UTC'
    }
    const [city, area] = timeZone.replaceAll('_', ' ').split('/').slice(1).reverse()
    const formattedLocation = area ? `${area} / ${city}` : city
    const offsetName = getOffsetName({ timeZone, iso })
    return `${formattedLocation} (${offsetName})`
  }
  const getSettings = () => {
    const timeZone = localStorage.getItem('TimeZone') || getSystemTimeZone()
    return { timeZone, region: getRegion({ timeZone }), location: getLocation({ timeZone }) }
  }

  // STATE
  const availableLocationsPerRegion = ref({
    ...Intl.supportedValuesOf('timeZone').reduce((acc: Record<string, Array<string>>, timeZone: string) => {
      const region = getRegion({ timeZone })
      const location = getLocation({ timeZone })
      if (!acc[region]) {
        acc[region] = []
      }
      acc[region].push(location)
      return acc
    }, {}),
    UTC: ['UTC']
  })
  const userSettings = ref(getSettings())
  const availableFormats = ref({
    date: [
      { title: 'mm/dd/yyyy', value: 'LL/dd/yyyy' },
      { title: 'dd/mm/yyyy', value: 'dd/LL/yyyy' },
      { title: 'yyyy/mm/dd', value: 'yyyy/LL/dd' }
    ],
    time: [
      { title: '12-Hour (1:00 PM)', value: 't' },
      { title: '24-Hour (13:00)', value: 'T' }
    ]
  })
  const format = ref({
    date: localStorage.getItem('dateFormat') || availableFormats.value.date[0].value,
    time: localStorage.getItem('timeFormat') || availableFormats.value.time[0].value
  })

  // GETTERS
  const getSystemSettings = () => {
    const timeZone = getSystemTimeZone()
    return { timeZone, region: getRegion({ timeZone }), location: getLocation({ timeZone }) }
  }
  const getUserDateTimeNow = ({ timeZone, toFormat, offset = {} }: { timeZone: string, toFormat?: string, offset?: DurationLike }) => {
    const date = DateTime.local().setZone(timeZone).plus(offset)
    return formatDateTime(date, toFormat)
  }
  const getUserDateTimeFromISO = ({ timeZone, iso, toFormat, offset = {} }: { timeZone: string, iso: string, toFormat?: string, offset?: DurationLike }) => {
    const date = DateTime.fromISO(iso, { zone: 'UTC' }).setZone(timeZone).plus(offset) || null
    return formatDateTime(date, toFormat)
  }
  const getUserDateTimeFromJSDate = ({ timeZone, jsDate, toFormat }: { timeZone: string, jsDate: Date, toFormat?: string }) => {
    const systemTimeZone = getSystemTimeZone()
    const date = DateTime.fromJSDate(jsDate, { zone: systemTimeZone }).setZone(timeZone)
    return formatDateTime(date, toFormat)
  }
  const getUTCDateTimeNow = ({ toFormat, offset = {} }: { toFormat?: string, offset?: DurationLike }) => {
    const date = DateTime.utc().plus(offset)
    return formatDateTime(date, toFormat)
  }
  const getUTCDateTimeFromISO = ({ timeZone, iso, toFormat, offset = {} }: { timeZone: string, iso: string, toFormat?: string, offset?: DurationLike }) => {
    const date = DateTime.fromISO(iso, { zone: timeZone }).setZone('UTC').plus(offset)
    return formatDateTime(date, toFormat)
  }
  const getUTCDateTimeFromJSDate = ({ jsDate, toFormat }: { jsDate: Date, toFormat?: string }) => {
    const systemTimeZone = getSystemTimeZone()
    const date = DateTime.fromJSDate(jsDate, { zone: systemTimeZone }).setZone('UTC')
    return formatDateTime(date, toFormat)
  }
  const getUTCDateTimeFromObject = ({ object, toFormat }: { object: DateObjectUnits, toFormat?: string }) => {
    const date = DateTime.fromObject(object, { zone: 'UTC' })
    return formatDateTime(date, toFormat)
  }

  // ACTIONS
  const saveUserSettings = ({ region, location }: UserSettings) => {
    const timeZone = getTimeZone({ region, location })
    localStorage.setItem('TimeZone', timeZone)
    userSettings.value.timeZone = timeZone
    userSettings.value.region = region
    userSettings.value.location = location
    threadStore.resetState()
  }
  const saveFormat = ({ date, time }: Format) => {
    localStorage.setItem('dateFormat', date)
    localStorage.setItem('timeFormat', time)
    format.value = { date, time }
  }

  return {
    // AUXILIARY
    getOffsetName,
    getLocation,
    // STATE
    availableLocationsPerRegion,
    userSettings,
    availableFormats,
    format,
    // GETTERS
    getSystemSettings,
    getUserDateTimeNow,
    getUserDateTimeFromISO,
    getUserDateTimeFromJSDate,
    getUTCDateTimeNow,
    getUTCDateTimeFromISO,
    getUTCDateTimeFromJSDate,
    getUTCDateTimeFromObject,
    // ACTIONS
    saveUserSettings,
    saveFormat
  }
})
