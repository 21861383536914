import { render } from 'vue'
import Snackbar from '~/components/organisms/snackbars/EmSnackbar.vue'

type SnackbarType = 'error' | 'warning' | 'message' | 'info' | 'success'

export default () => {
  const showSnackbar = (text: string, timeout: number, type: SnackbarType, title?: string, action?: Function) => {
    let componentInstance: any = null
    const nuxtApp = useNuxtApp()
    const mountEl = document.createElement('div')
    document.body.appendChild(mountEl)
    const snackbarComponent = h(Snackbar, {
      onDestroy: () => {
        const parent = componentInstance?.$el?.parentNode
        render(null, parent)
        parent?.parentNode?.removeChild(parent)
      }
    })
    snackbarComponent.appContext = nuxtApp.vueApp._context
    render(snackbarComponent, mountEl)
    componentInstance = snackbarComponent.component?.proxy
    const { show } = snackbarComponent.component?.exposed as { show({ text, timeout, type, title, action }: {text: string, timeout: number, type: SnackbarType, title?: string, action?: Function}): void }
    show({ text, timeout, type, title, action })
  }
  const error = (text: string, title?: string, action?: Function, timeout = 5000) => {
    showSnackbar(text, timeout, 'error', title, action)
  }
  const warning = (text: string, title?: string, action?: Function, timeout = 5000) => {
    showSnackbar(text, timeout, 'warning', title, action)
  }
  const message = (text: string, title?: string, action?: Function, timeout = 5000) => {
    showSnackbar(text, timeout, 'message', title, action)
  }
  const notify = (text: string, title?: string, action?: Function, timeout = 5000) => {
    showSnackbar(text, timeout, 'info', title, action)
  }
  const success = (text: string, title?: string, action?: Function, timeout = 5000) => {
    showSnackbar(text, timeout, 'success', title, action)
  }
  const desktopNotification = (title: string, body: string) => {
    const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window && Notification
    if (!isSupported()) {
      return
    }
    Notification.requestPermission(async (result) => {
      if (result === 'granted') {
        try {
          // eslint-disable-next-line no-new
          new Notification(title, { body })
        } catch {
          const registration = await navigator.serviceWorker.ready
          registration.showNotification(title, { body, vibrate: [100, 50, 100] })
        }
      }
    })
  }
  return {
    error,
    warning,
    message,
    notify,
    success,
    desktopNotification
  }
}
