<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    :fullscreen="fullscreen"
    persistent
    scrollable
    :content-class="fullscreen ? 'fixed-safe-area--full' : ''"
    transition="scale-transition"
  >
    <template #activator="slotAttrs">
      <slot name="activator" v-bind="slotAttrs" />
    </template>
    <v-card v-if="show">
      <EmLoadingBar :loading="loading" data-cy-form-field="dialog-loader" />
      <div class="flex-grow-0 d-flex justify-space-between align-center pa-4">
        <div class="d-flex align-center">
          <EmIcon v-if="showGoBack" icon="mdi-chevron-left" @click="goBack" />
          <EmBody1 semi-bold>
            {{ title }}
          </EmBody1>
          <slot name="title.append" />
        </div>
        <EmIcon v-if="!showGoBack && canClose" icon="mdi-close" aria-label="dialog-cancel" @click="close" />
      </div>
      <EmDivider />
      <div class="em-scrollbar flex-grow-1" :class="{'pa-4': !noPadding}">
        <slot />
      </div>
      <EmDivider />
      <div v-if="!hideActions" class="flex-grow-0 d-flex justify-end pa-4">
        <slot name="buttons">
          <EmTerciaryButton v-if="showCancel" aria-label="dialog-cancel" @click="close">
            {{ cancelText }}
          </EmTerciaryButton>
          <EmButton
            v-if="showConfirm"
            v-bind="confirmButtonProps"
            class="ml-2"
            aria-label="dialog-confirm"
            :data-cy="show ? 'dialog-confirm' : ''"
            :disabled="!canConfirm"
            :loading="loading"
            @click="ev=> $emit('confirm', ev)"
          >
            {{ confirmText }}
          </EmButton>
        </slot>
      </div>
    </v-card>
  </v-dialog>
</template>
<script setup>
import { useDisplay } from 'vuetify'
const display = ref(useDisplay())
const props = defineProps({
  modelValue: { type: Boolean, default: null },
  title: { type: String, default: '' },
  cancelText: { type: String, default: 'Close' },
  confirmText: { type: String, default: 'Confirm' },
  canConfirm: { type: Boolean, default: true },
  canClose: { type: Boolean, default: true },
  showCancel: { type: Boolean, default: true },
  showConfirm: { type: Boolean, default: true },
  showGoBack: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  hideActions: { type: Boolean, default: false },
  noPadding: { type: Boolean, default: false },
  confirmButtonProps: { type: Object, default: () => ({}) }
})
const emits = defineEmits(['open', 'close', 'update:modelValue', 'goBack', 'cancel', 'confirm'])
const dialogValue = ref(false)
const fullscreen = computed(() => display.value.smAndDown)
const show = computed({
  get () {
    if (props.modelValue !== null) {
      return props.modelValue
    }
    return dialogValue.value
  },
  set (newValue) {
    emits('update:modelValue', newValue)
    dialogValue.value = newValue
  }
})
watch(show, (val) => {
  if (val) {
    emits('open')
  } else {
    emits('close')
  }
})
const open = () => { show.value = true }
const goBack = () => emits('goBack')
const close = () => {
  show.value = false
  emits('cancel')
}
defineExpose({
  open,
  close
})
</script>
