import revive_payload_client_4sVQNw7RlN from "/home/runner/work/emissary-webgui/emissary-webgui/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/home/runner/work/emissary-webgui/emissary-webgui/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/emissary-webgui/emissary-webgui/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/emissary-webgui/emissary-webgui/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/emissary-webgui/emissary-webgui/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/runner/work/emissary-webgui/emissary-webgui/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/emissary-webgui/emissary-webgui/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eTVJQYlCmx from "/home/runner/work/emissary-webgui/emissary-webgui/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/emissary-webgui/emissary-webgui/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _1_apollo_Fo3em4oEfC from "/home/runner/work/emissary-webgui/emissary-webgui/plugins/1.apollo.ts";
import _10_sentry_OVJfhydHZE from "/home/runner/work/emissary-webgui/emissary-webgui/plugins/10.sentry.ts";
import _2_axios_WdR72Mlp4v from "/home/runner/work/emissary-webgui/emissary-webgui/plugins/2.axios.ts";
import _4_vuetify_YtahzYNSje from "/home/runner/work/emissary-webgui/emissary-webgui/plugins/4.vuetify.ts";
import _5_services_WH1lEJ6t68 from "/home/runner/work/emissary-webgui/emissary-webgui/plugins/5.services.ts";
import _6_init_5SWo8izu7M from "/home/runner/work/emissary-webgui/emissary-webgui/plugins/6.init.ts";
import _8_jobs_3q8Cr6ftyB from "/home/runner/work/emissary-webgui/emissary-webgui/plugins/8.jobs.ts";
import _9_vue_gtm_client_nFVcmEkhRU from "/home/runner/work/emissary-webgui/emissary-webgui/plugins/9.vue-gtm.client.js";
import Polyfill_gI7mQao5YM from "/home/runner/work/emissary-webgui/emissary-webgui/plugins/Polyfill.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  _1_apollo_Fo3em4oEfC,
  _10_sentry_OVJfhydHZE,
  _2_axios_WdR72Mlp4v,
  _4_vuetify_YtahzYNSje,
  _5_services_WH1lEJ6t68,
  _6_init_5SWo8izu7M,
  _8_jobs_3q8Cr6ftyB,
  _9_vue_gtm_client_nFVcmEkhRU,
  Polyfill_gI7mQao5YM
]