<template>
  <a v-bind="$attrs" class="cursor-pointer" @click="goTo">
    <slot />
  </a>
</template>
<script setup>
const props = defineProps({
  to: {
    type: String,
    default: ''
  },
  href: {
    type: String,
    default: ''
  },
  target: {
    type: String,
    default: ''
  }
})
const router = useRouter()
const goTo = () => {
  if (props.to) {
    router.push(props.to)
  } else if (props.target) {
    window.open(props.href, props.target)
  } else {
    window.location.href = props.href
  }
}
</script>
<style scoped>
a{
  color: rgb(var(--v-theme-primary-lighten-2)) !important;
  text-decoration: none;
}
</style>
