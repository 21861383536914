// Miliseconds per unit
enum MS_PER {
  Millisecond = 1,
  Second = 1000,
  Minute = Second * 60,
  Hour = Minute * 60,
  Day = Hour * 24,
  Week = Day * 7,
  Month = Day * 30,
  Year = Month * 12
}

export { MS_PER }
