import { defineStore } from 'pinia'

type Extension = {
    eid: string,
    page: string
}
type ResponseCallback = (response: any) => void

export const useChromeExtensionStore = defineStore('chromeExtension', () => {
  const chromeExtensionPrefs = ref({
    extension: null as Extension | null
  })

  const extension = () => computed({
    get: () => chromeExtensionPrefs.value.extension,
    set: (value) => {
      chromeExtensionPrefs.value.extension = value
    }
  })

  const login = async () => {
    if (!chromeExtensionPrefs.value.extension?.eid) { return }
    const { editable: { token } } = useUserStore()
    if (token) {
      const jwt = token
      await chrome.runtime.sendMessage(chromeExtensionPrefs.value.extension?.eid, { greeting: 'emissary-login', jwt })
    }
  }

  const logout = () => {
    chromeExtensionPrefs.value.extension = null
  }

  const sendCurrentPage = async (currentPage: string) => {
    if (!chromeExtensionPrefs.value.extension?.eid) { return }
    await chrome.runtime.sendMessage(chromeExtensionPrefs.value.extension?.eid, { greeting: 'emissary-set-current-page', currentPage })
  }

  const askForCampaignData = async (callback: ResponseCallback) => {
    if (!chromeExtensionPrefs.value.extension?.eid) { return }
    await chrome.runtime.sendMessage(chromeExtensionPrefs.value.extension?.eid, { greeting: 'get-campaign-data' }, {}, callback)
  }

  return {
    editable: {
      extension
    },
    login,
    logout,
    sendCurrentPage,
    askForCampaignData
  }
})
