import { computed } from 'vue'
export default () => {
  const { phoneNumberId } = useRouteParams()
  const user = useUserStore()
  const activePhoneNumber = computed(() => user.phoneById(phoneNumberId.value))
  const activePhoneNumberPhone = computed(() => activePhoneNumber.value?.phone_number)
  const activePhoneNumberReadonly = computed(() => activePhoneNumber.value?.read_only)
  const activePhoneNumberName = computed(() => activePhoneNumber.value?.inbox_name)
  // Permissions
  const CAMPAINGS_PERMISSIONS = {
    VIEW: 'campaigns.view',
    CREATE: 'campaigns.send',
    EDIT: 'campaigns.edit'
  }
  const hasPermission = (permission: string) => {
    return activePhoneNumber.value?.permissions.includes(permission)
  }
  const permissions = {
    campaigns: {
      VIEW: computed(() => hasPermission(CAMPAINGS_PERMISSIONS.VIEW)),
      CREATE: computed(() => hasPermission(CAMPAINGS_PERMISSIONS.CREATE)),
      EDIT: computed(() => hasPermission(CAMPAINGS_PERMISSIONS.EDIT))
    }
  }
  return {
    activePhoneNumber,
    activePhoneNumberReadonly,
    activePhoneNumberName,
    activePhoneNumberPhone,
    permissions,
    CAMPAINGS_PERMISSIONS
  }
}
